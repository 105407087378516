import React, {useEffect, useState} from "react";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {getProp, resourceIsCreated} from "../../../common/util/util-helpers";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import {Field, FieldsManager} from "../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import Card from "../../../common/components/card";
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";

const WorkOrderSettingsTab = ({translate}) => {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);

    /** Constants
     ================================================================= */
    const data = getProp(resource, 'data', {})
    const isLoading = getProp(resource, 'isLoading', false)

    const percentageDecimals = (value) => {
        if (countDecimals(value) >= 2) {
            return (countDecimals(value) - 2)
        } else {
            return 0
        }
    }
    const countDecimals = function (value) {
        if ((value % 1) != 0) {
            return value?.toString()?.split(".")[1]?.length ?? 0;
        }
        return 0;
    };

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            ShopSuppliesSurchargeTypeID: new Field('ShopSuppliesSurchargeTypeID', '', [], false, 'select', {addContainerClass: "col-span-6"}, {
                values: {
                    1: "No surcharge",
                    2: "Labor only",
                    3: "Labor and parts"
                },
                valueSort: true
            }),
            ShopSuppliesAmount: new Field('ShopSuppliesAmount', '', [], false, item.ShopSuppliesSurchargeTypeID === 1 ? 'hidden' : 'percentage', {addContainerClass: "col-span-3"}),
            ShopSuppliesMinAmount: new Field('ShopSuppliesMinAmount', '', [], false, item.ShopSuppliesSurchargeTypeID === 1 ? 'hidden' : 'float', {addContainerClass: "col-span-3"}),
            ShopSuppliesMaxAmount: new Field('ShopSuppliesMaxAmount', '', [], false, item.ShopSuppliesSurchargeTypeID === 1 ? 'hidden' : 'float', {addContainerClass: "col-span-3"}),
        }

         let data = {};

         data = fillFieldsFromData(fieldTemplates, item);

         data.ShopSuppliesAmount.value = ((data.ShopSuppliesAmount.value - 1) * 100).toFixed(percentageDecimals(data.ShopSuppliesAmount.value))

        return data
    };

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields(data))
    const [canSubmit, setCanSubmit] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.WorkOrderSettings
    }

    const getQuery = () => {
        return {}
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: getQuery(),
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        let validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            let params = FieldsManager.getFieldKeyValues(validatedFields);

            params.ShopSuppliesAmount = Number(params.ShopSuppliesAmount) / 100 + 1

            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: getResourceName(),
                piggyResource: getResourceName(),
                query: getQuery(),
                errorMessage: true, successMessage: translate('text.work_order_settings_update_success')
            }))
        } else {
            setFields(validatedFields)
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        let fieldsNew = fields;
        if (name === 'ShopSuppliesSurchargeTypeID') {
            fieldsNew.ShopSuppliesAmount.type = value === '1' ? 'hidden' : 'percentage'
            fieldsNew.ShopSuppliesMinAmount.type = value === '1' ? 'hidden' : 'float'
            fieldsNew.ShopSuppliesMaxAmount.type = value === '1' ? 'hidden' : 'float'

            if (value === '1') {
                fieldsNew.ShopSuppliesAmount.value =  0
                fieldsNew.ShopSuppliesMinAmount.value =  0
                fieldsNew.ShopSuppliesMaxAmount.value =  0
            }

        }
        setFields(FieldsManager.updateField(fieldsNew, name, value))
        setCanSubmit(true)
    }

    const handleFormCancel = () => {
        setFields(getFields(data))
        setCanSubmit(false)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        !isLoading && setFields(getFields(data))
    }, [isLoading])

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setCanSubmit(false);
        }
    })

    /** Render
     ================================================================= */
    const fieldsHtml = fieldsToHtml(Object.values(fields), translate, handleInputChange, {});

    return (
        <div className="space-y-8">
            <Card bodyClass={"py-8 px-6 space-y-4"}>
                <TabTitle text={translate("title.shop_supplies")}/>
                {isLoading &&
                    <div className={'flex justify-center py-3'}>
                        <LoaderSmall/>
                    </div>
                }

                <div
                    className={'grid grid-cols-6 gap-4'}>
                    {!isLoading && fieldsHtml}
                </div>

                <PageFooter
                    translate={translate}
                    canSubmit={canSubmit}
                    actionCancel={handleFormCancel}
                    actionSubmit={onSubmit}
                />
            </Card>
        </div>
    )
}

export default WorkOrderSettingsTab
