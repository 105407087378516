import React, {useRef, useState} from "react";
import {getLookup, getProp} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import {ArrowLeftIcon, MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import Buttons from "../../../../common/components/buttons";
import FormCard from "../../../../common/components/forms/form-card";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";

export default function WorkOrdersCreateInfoTab({
                                                    isLoading,
                                                    translate,
                                                    isCreate,
                                                    history,
                                                    fieldsBasic,
                                                    fieldsAssigned,
                                                    fieldsAssets,
                                                    fieldsComplaint,
                                                    handleComplaintChange,
                                                    handleBasicInputChange,
                                                    handleAssignedChange,
                                                    handleAssetsChange,
                                                    isLayoutScrolled,
                                                    scrollToTop
                                                }) {

    const basicInfoRef = useRef();
    const assignedToRef = useRef();
    const assetsRef = useRef();
    const complaintsRef = useRef();
    const [sideMenu, setSideMenu] = useState(
        [
            {
                key: "basicInfo",
                label: translate('text.basicInfo'),
                ref: basicInfoRef,
                expanded: true
            },
            {
                key: "assignedTo",
                label: translate('text.service_provider'),
                ref: assignedToRef,
                expanded: true
            },
            {
                key: "assets",
                label: translate('text.assets'),
                ref: assetsRef,
                expanded: true
            },
            {
                key: "complaints",
                label: translate('text.Complaint'),
                ref: complaintsRef,
                expanded: true
            }
        ])

    const Requester = getProp(fieldsBasic, "Requester.value", "");
    const RequestedDate = getProp(fieldsBasic, "CreatedDate.value", "");


    /** Helpers
     ================================================================= */
    const updateParams = (params) => {
        params.WorkOrderStatusID = Number(params.WorkOrderStatusID.value);

        return params;
    }

    const handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "center", behavior: "smooth"})
    }

    const toggleExpandSection = (sectionName) => {
        let sections = Object.values(sideMenu)

        sections.map(it => {
            if (sectionName === it.key) {
                it.expanded = !it.expanded;
            }
            return it;
        })

        setSideMenu(sections)

    }

    /** Lifecycle
     ================================================================= */

    /** Component Body
     ================================================================= */
    return (
        <div>
            <div className="xl:max-w-3xl md:ml-44 mx-6 xl:mx-auto py-8 space-y-6 relative mb-64">
                <div
                    className="hidden w-36 h-full absolute md:block right-full ml-6">
                    <div className="fixed h-10 top-30">
                        <div
                            className="space-y-1 pr-6"
                        >
                            {isLayoutScrolled && (
                                <div className="flex relative right-4">
                                    <div>
                                        <Buttons
                                            buttons={[
                                                {
                                                    iconLeading: ArrowLeftIcon,
                                                    className: "btn btn-header",
                                                    onClick: () => history.push('/work-orders/'),
                                                    title: "Go back to work orders list"
                                                }
                                            ]}
                                        />
                                    </div>

                                    <button
                                        onClick={() => scrollToTop()}
                                        className="btn btn-text ml-2 hover:bg-primary-transparent hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                    >
                                        {translate("text.scroll_to_top")}
                                    </button>
                                </div>
                            )}

                            {sideMenu.map(it => {
                                return (<div className={"flex relative right-8"}>
                                        <button
                                            className="flex btn-icon rounded-button hover:bg-tm-gray-200"
                                            onClick={() => toggleExpandSection(it.key)}
                                        >
                                            {!it.expanded && (
                                                <PlusIcon className="w-5 h-5 text-green-600"/>
                                            )}

                                            {it.expanded && (
                                                <MinusIcon className="w-5 h-5"/>
                                            )}
                                        </button>

                                        <button
                                            onClick={() => handleScrollToSectionClick(it.ref)}
                                            className="text-tm-gray-800 hover:bg-inverse flex items-center px-3 py-2 text-sm font-medium rounded-btn w-full"
                                        >
                                            <span className="truncate">{it.label}</span>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <FormCard
                    innerRef={basicInfoRef}
                    title={translate('text.basicInfo')}
                    strapLine={
                        !isCreate ? Requester ?
                            translate("text.work_order_requester_requested_on", [Requester, RequestedDate]) :
                            translate("text.work_order_requested_on", [RequestedDate]) : null
                    }
                    isLoading={isLoading}
                    onInputChange={handleBasicInputChange}
                    fields={fieldsBasic}
                    excludedFields={['RequesterID', 'CreatedDate']}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[0].expanded}
                    toggleExpandSection={() => toggleExpandSection('basicInfo')}
                />

                <FormCard
                    innerRef={assetsRef}
                    title={translate('text.assets')}
                    isLoading={isLoading}
                    onInputChange={handleAssetsChange}
                    fields={fieldsAssets}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[2].expanded}
                    toggleExpandSection={() => toggleExpandSection('assets')}
                />

                <FormCard
                    innerRef={complaintsRef}
                    title={translate('text.Complaint')}
                    isLoading={isLoading}
                    onInputChange={handleComplaintChange}
                    fields={fieldsComplaint}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[3].expanded}
                    toggleExpandSection={() => toggleExpandSection('complaints')}
                />

                <FormCard
                    innerRef={assignedToRef}
                    title={translate('text.service_provider')}
                    isLoading={isLoading}
                    onInputChange={handleAssignedChange}
                    fields={fieldsAssigned}
                    selects={getSelects()}
                    addFieldContainerClass={"col-span-6"}
                    translate={translate}
                    isExpanded={sideMenu[1].expanded}
                    toggleExpandSection={() => toggleExpandSection('assignedTo')}
                />
            </div>
        </div>
    )
}

function getSelects() {
    return {
        AssignedContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        RequesterID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        AssignedVendorID: {
            api: 'api/' + Resources.Vendors,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.VendorID,
                metadata: it
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            searchMap: (it) => {
                return ({
                    label: it.TruckNumber + " - " + it.Manufacturer + ", " + it.Model + ", " + it.Year + " (VIN:" + it.VIN + ")",
                    value: it.TruckID
                })
            }

        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            searchMap: (it) => {
                return ({
                    label: it.TrailerNumber + " - " + it.Manufacturer + ", " + it.Model + ", " + it.Year + " (VIN:" + it.VIN + ")",
                    value: it.TrailerID
                })
            }
        },
        RequestedServiceCategoryID: getLookup('RequestedServiceCategory'),
        VMRSCode: {
            api: 'api/' + Resources.WorkOrdersVMRSCode,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.value,
                    value: it.value,
                })
            }
        },
        CustomerID: {
            api: 'api/' + Resources.CustomersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName || item.Organization,
                value: item.CustomerID
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
        ChargeContactID: {
            api: "api/" + Resources.Contacts,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName,
                Contact: item
            })
        },
        ChargeOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        }
    }
}

