import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {classNames, getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {checkPerm, getUser} from "../../../util/util";
import WorkOrdersInfoTab from "./work-orders-info-tab";
import WorkOrdersLaborPartsTab from "./work-orders-labor-parts-tab";
import WorkOrdersLinkedTab from "./work-orders-linked-tab";
import WorkOrdersImagesTab from "./work-orders-images-tab";
import WorkOrdersNotesTab from "./work-orders-notes-tab";
import WorkOrdersMoneyCodesTab from "./work-orders-money-codes-tab";
import WorkOrdersServices from "./work-orders-services-tab";
import {CREATE_PERM, READ_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {ArrowLeftIcon, CheckBadgeIcon} from "@heroicons/react/24/outline";
import Layout from "../../../common/components/layout";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NavResponsive from "../../../common/components/nav-responsive";
import DocumentsList from "../../../common/components/tabs/documents-list";
import {Field} from "../../../data/services/fields";
import {CurrencyDollarIcon, ReceiptPercentIcon} from "@heroicons/react/20/solid";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import {getThirdResource, updateThirdResource} from "../../../data/actions/thirdResource";
import WorkOrdersAppointmentsTab from "./work-order-appointments-tab";
import LocalStorage from "../../../util/localStorage";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Tippy from "@tippyjs/react";
import {toFrontDateTime} from "../../../common/util/util-dates";
import {getCountTaskResource} from "../../../data/actions/taskResource";
import {showGlobalModal} from "../../../data/actions/ui";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import ModalDefault from "../../../common/components/modal/modal-default";

export default function WorkOrdersSingleView({history, match, translate}) {
    const ID = match.params.id;

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const secondResource = useSelector((state) => state.secondResource);
    const document = useSelector((state) => state.document);
    const download = useSelector((state) => state.download);
    const thirdResource = useSelector((state) => state.thirdResource);
    const taskResource = useSelector((state) => state.taskResource);
    const dialogResource = useSelector((state) => state.dialogResource);

    const isLoading = getProp(thirdResource, "isLoading", true);
    const secondIsLoading = getProp(secondResource, "isLoading", true);
    const documentIsLoading = getProp(document, "isLoading", false);
    const downloadIsLoading = getProp(download, "isLoading", false);
    const data = getProp(resource, "data", {});
    const dataInfo = getProp(thirdResource, "data", {});

    const isInvoiced = getProp(thirdResource, "data.IsInvoiced", false);
    const isPaid = getProp(thirdResource, "data.IsPaid", false);
    const isAuthorized = getProp(thirdResource, "data.IsAuthorized", false);

    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);

    const layoutRef = useRef();
    const [isLayoutScrolled, setIsLayoutScrolled] = useState(false)
    const WoColorTag = getProp(thirdResource, 'data.ColorTag', "");
    const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] = useState(false)
    const [resourceTaskModalOpen, setResourceTaskModalOpen] = useState(false)

    const TaskCount = getProp(taskResource, 'count.count', 0)
    const openTaskCountTwoDigits = TaskCount > 9;


    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getThirdResource({
            user: getUser(),
            query: {
                WorkOrderID: ID
            },
            resource: Resources.WorkOrder
        }))
    }

    const fetchTaskResourceCount = () => {
        dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    /** UI Events
     ================================================================= */

    useEffect(() => {
        fetchTaskResourceCount()
    }, []);
    const handleTabChange = (selectedTab) => {
        const tabsUpdate = tabs.map(tab => {
            tab.current = tab.resource === selectedTab;
            return tab;
        });

        setTabs(tabsUpdate);
    }

    const handleLayoutScroll = () => {
        if (layoutRef?.current.scrollTop > 100 && !isLayoutScrolled) {
            setIsLayoutScrolled(true)
        }

        if (layoutRef?.current.scrollTop <= 100 && isLayoutScrolled) {
            setIsLayoutScrolled(false)
        }
    }

    const handleScrollLayoutOnTop = () => {
        if (layoutRef?.current) {
            layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const handleToggleAuthorizedModal = () => {
        setIsAuthorizationModalOpen(!isAuthorizationModalOpen)
    }

    const getAuthorizedModalFields = () => {
        let fieldTemplates = {
            AuthorizeNote: new Field('AuthorizeNote', '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
        }

        return fieldTemplates
    }

    const handleCreateNewResourceTask = () => {
        dispatch(showGlobalModal('createTaskDialog', {taskResource: match.url}))
    }

    const handleToggleResourceTasks = () => {
        setResourceTaskModalOpen(!resourceTaskModalOpen)
    }

    /** Helpers
     ================================================================= */
    function getTabs() {
        return [
            {
                name: 'WorkOrdersInfo',
                resource: Resources.WorkOrder,
                current: true,
                visible: true
            },
            {
                name: 'laborAndParts',
                resource: Resources.WorkOrderParts,
                current: false,
                visible: true
            },
            {
                name: 'Appointments',
                resource: Resources.WorkOrderSchedule,
                current: false,
                visible: true
            },
            {
                name: 'Images',
                resource: Resources.WorkOrdersImages,
                current: false,
                visible: true
            },
            {
                name: 'Services',
                resource: 'Services',
                current: false,
                visible: checkPerm(Resources.WorkOrdersServicesTruck, READ_PERM) || checkPerm(Resources.WorkOrdersServicesTrailer, READ_PERM)
            },
            {
                name: 'MoneyCodes',
                resource: Resources.MoneyCodes,
                current: false,
                visible: true
            },
            {
                name: 'Notes',
                resource: Resources.WorkOrderNotes,
                current: false,
                visible: true
            },
            {
                name: 'LinkedWorkOrders',
                resource: Resources.WorkOrdersLinked,
                current: false,
                visible: true
            },
            {
                name: 'WorkOrdersDocument',
                resource: Resources.WorkOrdersDocuments,
                current: false,
                visible: true
            }
        ]
    }

    /** Page Body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
            layoutRef={layoutRef}
            onScroll={handleLayoutScroll}
        >
            <PageHeader
                title={translate("page_title.work-order") + " - " + (dataInfo.AutoCounter ?? '')}
                isLoading={isLoading}
                removeBorder={true}
                ButtonsLeading={[
                    {
                        iconLeading: ArrowLeftIcon,
                        className: "btn btn-header m-2",
                        onClick: () => history.push('/work-orders/'),
                        title: "Go back to work orders list"
                    }
                ]}
                afterTitle={
                    <div className={"flex w-[calc(100vw-34rem)]"}>
                        <div className="flex flex-shrink-0 items-center space-x-2 ml-4">
                            {!isPaid && (
                                <ReceiptPercentIcon
                                    className={classNames('w-5 h-5', isInvoiced ? 'text-green-600' : 'text-tm-gray-400')}
                                />
                            )}

                            {isPaid && (
                                <CurrencyDollarIcon
                                    className={classNames('w-5 h-5 text-green-600')}
                                />
                            )}

                            <span
                                className={classNames('text-sm font-semibold', isInvoiced || isPaid ? 'text-green-600' : 'text-tm-gray-500')}
                            >
                                    {
                                        isPaid
                                            ? translate('text.isPaid')
                                            : isInvoiced
                                                ? translate('text.invoiced')
                                                : translate('text.not_invoiced')
                                    }
                            </span>
                        </div>

                        {!isLoading && (
                            <Tippy content={<div><p>{`Authorized by: ${dataInfo.AuthorizedByContact}`}</p>
                                <p>{`Authorize date: ${toFrontDateTime(dataInfo.AuthorizedUpdateDate)}`}</p>
                                <p>{`Authorize note: ${dataInfo.AuthorizeNote}`}</p></div>} disabled={!isAuthorized}>
                                <button className="flex flex-shrink-0 items-center space-x-2 ml-4 btn btn-outline"
                                        onClick={() => handleToggleAuthorizedModal()}>
                                    {!!isAuthorized ?
                                        (
                                            <CheckBadgeIcon
                                                className={classNames('w-5 h-5 text-green-600')}
                                            />
                                        )
                                        :
                                        (
                                            <CheckBadgeIcon
                                                className={classNames('w-5 h-5 text-tm-gray-400')}
                                            />
                                        )
                                    }
                                    <span
                                        className={classNames('text-sm font-semibold', isAuthorized ? 'text-green-600' : 'text-tm-gray-500')}
                                    >
                                        {
                                            isAuthorized
                                                ? translate('text.authorized')
                                                : translate('text.not_authorized')
                                        }
                                </span>

                                </button>
                            </Tippy>
                        )}

                        <div>
                            <ResourceTableTags data={WoColorTag} addClass="py-2 mx-2"/>
                        </div>

                        <div className="flex ml-auto flex-wrap">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <button className="btn btn-outline mr-3 mt-0.5"
                                        onClick={() => handleCreateNewResourceTask()}>{translate('btn.new_wo_task')}
                                </button>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -right-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                }
            />

            <NavResponsive
                addClass="px-4 sm:px-6 md:px-8 h-9"
                tabs={tabs}
                onTabChange={(it) => (!isLoading || currentTab?.name !== 'WorkOrdersInfo') && handleTabChange(it)}
                translate={translate}
            />

            {currentTab.name === "WorkOrdersInfo" && (
                <WorkOrdersInfoTab
                    dataInfo={dataInfo}
                    isLoading={isLoading}
                    dispatch={dispatch}
                    getResourcePath={currentTab.resource}
                    fetchData={fetchData}
                    translate={translate}
                    history={history}
                    layoutRef={layoutRef}
                    handleTabChange={(it) => handleTabChange(it)}
                    isLayoutScrolled={isLayoutScrolled}
                    scrollToTop={handleScrollLayoutOnTop}
                    isParts={history.location.search === "?parts"}
                    ID={ID}
                />
            )}

            {currentTab.name === "laborAndParts" && (
                <WorkOrdersLaborPartsTab
                    ID={ID}
                    dispatch={dispatch}
                    translate={translate}
                    isInvoiced={isInvoiced}
                />
            )}

            {currentTab.name === "Appointments" && (
                <WorkOrdersAppointmentsTab
                    ID={ID}
                    dispatch={dispatch}
                    translate={translate}
                />
            )}

            {currentTab.name === "Images" && (
                <WorkOrdersImagesTab
                    data={data}
                    ID={ID}
                    translate={translate}
                />
            )}

            {currentTab.name === "Services" && (
                <WorkOrdersServices
                    data={data}
                    ID={ID}
                    dispatch={dispatch}
                    resource={resource}
                    isLoading={isLoading || documentIsLoading || downloadIsLoading}
                    translate={translate}
                    TruckID={dataInfo.TruckID}
                    TrailerID={dataInfo.TrailerID}
                    Truck={dataInfo.Truck}
                    Trailer={dataInfo.Trailer}
                    VendorID={dataInfo.VendorID}
                    Vendor={dataInfo.Vendor}
                    secondResource={secondResource}
                    secondIsLoading={secondIsLoading}
                />
            )}

            {currentTab.name === "MoneyCodes" && (
                <WorkOrdersMoneyCodesTab
                    data={data}
                    ID={ID}
                    dispatch={dispatch}
                    isLoading={isLoading}
                    translate={translate}
                />
            )}

            {currentTab.name === "Notes" && (
                <WorkOrdersNotesTab
                    data={data}
                    ID={ID}
                    dispatch={dispatch}
                    resource={resource}
                    isLoading={isLoading || documentIsLoading || downloadIsLoading}
                    translate={translate}
                />
            )}

            {currentTab.name === "LinkedWorkOrders" && (
                <WorkOrdersLinkedTab
                    ID={ID}
                    dispatch={dispatch}
                    resource={resource}
                    isLoading={isLoading}
                    translate={translate}
                />
            )}

            {currentTab.name === "WorkOrdersDocument" && (
                <DocumentsList
                    id={ID}
                    deleteDocumentKey={"DocumentID"}
                    //primarySortKey={"CreateUpdateDate"}
                    disableEdit={true}
                    haveUpdatePermission={checkPerm(Resources.WorkOrdersDocuments, UPDATE_PERM)}
                    documentsResource={Resources.WorkOrdersDocuments}
                    downloadDocumentResource={Resources.WorkOrdersDocumentsSingle}
                    locationPath={"work-orders-documents-tab"}
                    // primaryDocumentKey={"WorkOrderID"}
                    getDocumentUploadFields={(item) => {
                        return {
                            "WorkOrderID": new Field('WorkOrderID', ID, ['empty'], false, 'hidden'),
                            "Description": new Field('Description', item?.Description ?? "", ['empty'], false, 'text', {addContainerClass: "col-span-full"})
                        }
                    }}
                    getDocumentTableFields={() => {
                        return {
                            "DocumentName": new Field('DocumentName', '', [''], false),
                            "Description": new Field('Description', '', [''], false),
                            "CreateUpdateDate": new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
                            "UploadedBy": new Field('UploadedBy', '', [''], false, 'custom', {
                                render: (item) => {
                                    return (<div>{item.FirstName} {item.LastName}</div>)
                                },
                                omitSort: true
                            })
                        }
                    }}
                    onGetQueryFields={() => {
                        return {
                            "searchFields": new Field('searchFields', JSON.stringify({WorkOrderID: ID}), [''], false, 'hidden'),
                        }
                    }}
                    translate={translate}
                />
            )}

            <ModalSaveResource
                title={!!isAuthorized ? translate('text.remove_authorization') : translate('text.authorized_modal')}
                widthClass="max-w-md"
                gridColsClass="grid-cols-3"
                show={isAuthorizationModalOpen}
                buttonLabel={translate("btn.remove")}
                onClose={() => handleToggleAuthorizedModal()}
                fields={getAuthorizedModalFields(dataInfo)}
                // handleInputChange={this.handleInputChange}
                canSubmit={!!isAuthorized}
                onSubmit={(params) => {
                    if (params) {
                        params.WorkOrderID = ID;

                        dispatch(updateThirdResource({
                            user: LocalStorage.get('user'),
                            query: {
                                WorkOrderID: ID
                            },
                            params: params,
                            resource: Resources.WorkOrderAuthorization,
                            piggyResource: Resources.WorkOrder,
                            errorMessage: true, successMessage: 'Authorization updated.',
                        }))
                        handleToggleAuthorizedModal()
                    }
                }}
                translate={translate}
            />

            <ModalDefault
                show={!!resourceTaskModalOpen}
                widthClass={'max-w-3xl'}
                title={translate('text.truck_tasks')}

                closeButtonLabel={translate('btn.close')}
                onClose={handleToggleResourceTasks}

                translate={translate}
            >
                <ResourceTasksDialog
                    close={handleToggleResourceTasks}
                    dispatch={dispatch}
                    translate={translate}
                    dialogResource={dialogResource}
                    taskResource={taskResource}
                    large={true}
                />
            </ModalDefault>
        </Layout>
    )
}
