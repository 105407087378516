import React, { useEffect, useState } from "react";
import ModalSaveResource from "./index";

// Displays errorMessages and closes after submit automatically
export default function ModalSaveResourceAuto({
                                                   fields,
                                                   onSubmit,
                                                   onClose,
                                                   translate,
                                                   title,
                                                   metadata,
                                                   buttonLabel,
                                                   htmlBefore,
                                                   htmlAfter,
                                                   addBodyClass,
                                                   show,
                                                   isLoading,
                                                   onInputChange,
                                                   onValidateFields,
                                                   getRawFields,
                                                   fieldLabelType,
                                                   addFieldContainerClass,
                                                   initialFocusID,
                                                   resource,
                                                   isDirty,
                                                   closeAfterSubmitSuccess = true,
                                               }) {

    /** State
     ================================================================= */
    const [resourceErrorMessage, setResourceErrorMessage] = useState(undefined)

    /** Lifecycle
     ================================================================= */

    useEffect(() => {
        // Clears errormessage on open
        if (show) {
            setResourceErrorMessage(undefined);
        }
    }, [show])

    useEffect(() => {
        if (!!resource?.error) {
            setResourceErrorMessage(resource.errorMessage)
        }
    }, [resource?.error]);

    useEffect(() => {
        if (!!closeAfterSubmitSuccess && (!!resource?.create?.id || !!resource.update)) {
            onClose();
        }
    }, [resource])

    return (
        <ModalSaveResource
            show={show}
            fields={fields}
            initialFocusID={initialFocusID}
            onInputChange={onInputChange}
            title={title}
            onClose={onClose}
            onSubmit={onSubmit}
            addFieldContainerClass={addFieldContainerClass}
            addBodyClass={addBodyClass}
            onValidateFields={onValidateFields}
            getRawFields={getRawFields}
            fieldLabelType={fieldLabelType}
            isLoading={isLoading}
            metadata={metadata}
            buttonLabel={buttonLabel}
            htmlBefore={htmlBefore}
            htmlAfter={htmlAfter}
            resource={resource}
            translate={translate}
            isDirty={isDirty}
            dialogErrorMessage={resourceErrorMessage}
        />
    );

}
