import React from "react";
import { classNames } from "../../../../../util/util-helpers";
import Buttons from "../../../../buttons";
import {LoaderSmall} from "../../../../loader";

export default function PageTabWithFooter({children, customFooter, buttons, buttonsLeft, isLoading, layoutRef}) {
    return (
        <div className="flex flex-col space-y-2">
            <div ref={layoutRef} className="overflow-y-visible">
                {children}
            </div>

            <footer className={classNames(
                "fixed bottom-0 left-0 right-0 flex px-8 h-14 items-center border-tm-gray-200 border-t gap-2 bg-inverse rounded-b-lg"
            )}>
                {customFooter}

                {!customFooter && (
                    <React.Fragment>
                        <div className="space-x-4">
                            <Buttons
                                buttons={buttonsLeft}
                            />
                        </div>

                        <div className="ml-auto gap-x-4 flex">
                            {isLoading && (
                                <div className="items-center flex">
                                    <LoaderSmall
                                        disableContainer
                                    />
                                </div>
                            )}

                            <Buttons
                                buttons={buttons}
                            />
                        </div>
                    </React.Fragment>
                )}
            </footer>
        </div>
    )
}
