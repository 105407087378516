import {ChevronRightIcon, XMarkIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useState} from "react";
import Modal from "../index";

export default function ModalCreateSuccessNew({
                                               resource,
                                               position,
                                               dialogWidth,
                                               successText,
                                               translate,
                                               buttons,
                                               isItemCreatedRef
                                           }) {

    const [isCreateSuccessDialogOpen, setIsCreateSuccessDialogOpen] = useState(false)
    const [createdResourceID, setCreatedResourceID] = useState(undefined);
    const [createdResourceAutoCounter, setCreatedResourceAutoCounter] = useState(undefined);

    useEffect(() => {
        if (resource?.create?.id && isItemCreatedRef.current) {
            setCreatedResourceID(resource.create.id);
            setCreatedResourceAutoCounter(resource.create.AutoCounter)

            setTimeout(() => {
                setIsCreateSuccessDialogOpen(true);
            }, 500)
        }
    }, [resource?.create]);

    const closeCreateSuccessDialog = () => {
        setIsCreateSuccessDialogOpen(false);
    }

    return (
        <Modal
            show={isCreateSuccessDialogOpen}
            onClose={closeCreateSuccessDialog}
            position={position}
            dialogWidth={dialogWidth}
        >
            <div className="px-6 pt-6 pb-8 flex flex-col w-full gap-y-4">

                <p className="text-base text-tm-gray-700">{!!successText ? successText(createdResourceAutoCounter, closeCreateSuccessDialog) : translate("text.where_go_next")}</p>

                {buttons(createdResourceID, closeCreateSuccessDialog, createdResourceAutoCounter).map(button => (
                    <button
                        key={button.label}
                        onClick={button.onClick}
                        className={button.className ?? "btn btn-outline pr-3 text-link font-bold bg-inverse hover:bg-primary hover:text-inverse focus:border-primary"}
                    >
                        {!!button.iconLeading && (
                            <button.iconLeading className="w-5 h-5 mr-2"/>
                        )}

                        {button.label}

                        {!button.iconTrailing && button.iconTrailing === undefined && (
                            <ChevronRightIcon className="w-5 h-5 ml-auto"/>
                        )}

                        {!!button.iconTrailing && (
                            <button.iconTrailing className="w-5 h-5 ml-auto"/>
                        )}
                    </button>
                ))}
            </div>

            <button
                onClick={closeCreateSuccessDialog}
                className="btn btn-close absolute right-2 top-2"
            >
                <XMarkIcon/>
            </button>
        </Modal>
    )
}
