import React, {useEffect, useRef, useState} from "react";

import {Field, FieldsManager} from "../../../../data/services/fields";
import {createResource, getResource, updateResource} from "../../../../data/actions/resource";
import Resources from "../../../../data/services/resources";
import useQuery from "../../../../hooks/use-query";
import LocalStorage from "../../../../util/localStorage";
import {
    DEFAULT_LIMIT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_OFFSET,
    DEFAULT_SORT
} from "../../../../util/util-constants";
import {getProp, resourceIsCreated} from "../../../../util/util";
import FieldText from "../../../../common/components/fields/field-text";
import {useSelector} from "react-redux";
import {getSecondResource} from "../../../../data/actions/secondResource";
import moment from "moment";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import Buttons from "../../../../common/components/buttons";
import {LoaderLarge} from "../../../../common/components/loader";
import Card from "../../../../common/components/card";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalDefault from "../../../../common/components/modal/modal-default";
import CopyToClipboardButton from "../../../../common/components/buttons/copy-to-clipboard-button";


export default function WorkOrdersMoneyCodesTab({ID, dispatch, translate, isLoading}) {

    /** Constants
     ================================================================= */
    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [isEditResourceDialogOpen, setIsEditResourceDialogOpen] = useState(false);
    const [isReceiveDialogOpen, setIsReceiveDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const resource = useSelector((state) => state.resource);
    const secondResource = useSelector((state) => state.secondResource);
    let isItemCreatedRef = useRef(false);

    const getResourcePath = () => Resources.MoneyCodes;
    const getListPath = () => getResourcePath() + "_list"


    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {hideLabel: true, labelType: "float"}),
            StartDate: new Field('StartDate', '', [''], false, 'date',
                {hasActiveBadge: true, labelType: "float"},
                {
                    isClearable: true
                }),
            EndDate: new Field('EndDate', '', [''], false, 'date',
                {hasActiveBadge: true, labelType: "float"},
                {
                    isClearable: true,
                }),
            ContactID: new Field('ContactID', '', [''], false, 'select-search',
                {hasActiveBadge: true, labelType: "float"},
                {
                    isClearable: true
                }),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [''], false, 'select-search',
                {hasActiveBadge: true, labelType: "float"},
                {
                    isClearable: true,
                }),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {
                labelType: "float",
                label: "IsPayed",
                hasActiveBadge: true
            }),
            IsVoided: new Field('IsVoided', '', [''], false, 'checkbox',
                {
                    labelType: "float",
                    label: "IsVoided",
                    hasActiveBadge: true
                }),

            sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
            sortBy: new Field('sortBy', '', [''], false, 'hidden'),
            offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
            limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {
                placeholder: "100",
                menuPlacement: "top"
            })
        }
    }

    const [queryFields, setQueryFields] = useQuery(getQueryFilterFields(), getListPath());

    const getQuery = () => {
        const {
            ContactID,
            StartDate,
            EndDate,
            IsPayed,
            IsVoided,
            MoneyCodeTypeID
        } = FieldsManager.getFieldKeyValues(queryFields)

        return {
            sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
            sortBy: new Field('sortBy', '', [''], false, 'hidden'),
            offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
            limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {
                placeholder: "100",
                menuPlacement: "top"
            }),
            ...FieldsManager.getFieldKeyValues(queryFields),
            searchFields: JSON.stringify({
                ContactID: ContactID,
                IsPayed: IsPayed,
                IsVoided: IsVoided,
                MoneyCodeTypeID: MoneyCodeTypeID,
                ...(!!StartDate && {StartDate: ['Date', '>', StartDate]}),
                ...(!!EndDate && {EndDate: ['Date', '<', EndDate]}),
                WorkOrderID: ID
            })
        }
    }

    const getQueryIntegrated = () => {
        const initialStartDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
        const initialEndDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')

        return {
            sort: "ASC",
            sortBy: "",
            offset: 0,
            query: "",
            StartDate: initialStartDate,
            EndDate: initialEndDate
        }
    }

    const fetchData = (dispatch) => {
        dispatch(getResource({
                user: LocalStorage.get('user'),
                resource: getResourcePath(),
                query: getQuery()
            }
        ))
    }

    const fetchIntegrationData = (dispatch) => {
        dispatch(getSecondResource({
                user: LocalStorage.get('user'),
                resource: Resources.MoneyCodesIntegrated,
                query: getQueryIntegrated()
            }
        ))
    }

    useEffect(() => {
        fetchIntegrationData(dispatch)
    }, []);

    useEffect(() => {
        fetchData(dispatch)
    }, [queryFields]);

    useEffect(() => {
        if (resourceIsCreated(resource) && isItemCreatedRef.current) {
            const receivedCode = getProp(resource, 'create.code', 0);
            setFields(getFields(receivedCode));
            setIsReceiveDialogOpen(true);
            // setTimeout(() => {
            //     dispatch(clearCreateResource());
            // }, 500)
        }

    }, [resource]);


    const getFields = (receivedCode) => {

        return {
            // Table
            Code: new Field('Code', receivedCode, [], false, 'text'),
            ContactID: new Field('ContactID', '', [], false, 'select-search'),
            Date: new Field('Date', '', [], false, 'datetimezone'),
            Amount: new Field('Amount', '', [], false, 'money'),
            Description: new Field('Description', '', [], false, 'textarea', {label: 'notes'}),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', [], false, 'select-search'),
            WorkOrderID: new Field('WorkOrderID', '', [], false, 'select-search', {omitSort: true}),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {label: 'Processed'}),
            IsVoided: new Field('IsVoided', '', [], false, 'checkbox'),
            IssuedByContact: new Field('IssuedByContact', '', [], false, 'text'),
            IntegrationType: new Field('IntegrationType', '', [], false, 'text')
        }
    }

    const [fields, setFields] = useState(getFields());

    const getCreateFields = () => {
        return {
            // Table
            Amount: new Field('Amount', '', ['empty'], false, 'float', {addContainerClass: "col-span-full"}),
            IssuedToID: new Field('IssuedToID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
        }
    }

    const getEditFields = (item) => {
        let fieldTemplates = {
            ContactID: new Field('ContactID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: "IssuedToID"
            }),
            MoneyCodeTypeID: new Field('MoneyCodeTypeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Description: new Field('Description', '', [], false, 'textarea', {
                label: 'notes',
                addContainerClass: 'col-span-full'
            }),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        return fieldTemplates;
    }

    function getSelects() {
        return {
            ContactID: {
                api: 'api/' + Resources.Contacts,
                query: {},
                searchMap: (it) => ({
                    label: `${it.FirstName} ${it.LastName}`,
                    value: it.ContactID,
                })
            },
            MoneyCodeTypeID: {
                api: 'api/' + Resources.MoneyCodeType,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.MoneyCodeTypeID,
                    label: item.Reason
                })
            }
        }
    }

    const handleEditResourceClick = (item) => {
        setIsEditResourceDialogOpen(true);
        setSelectedItem(item);
    }
    const handleToggleResourceDialog = () => {
        setIsResourceDialogOpen(!isResourceDialogOpen);
    }

    const hasEFSIntegration = !!getProp(secondResource.data, 'integrations.EFS.hasIntegration', 1)

    const noEFSIntegrationMessage = (
        <div>
            No money code provider integrated
        </div>
    )
    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            {(hasEFSIntegration) ? (
                <React.Fragment>
                    <InfoParagraph type='info'>
                        {translate('text.listOfMoneyCodes')}
                    </InfoParagraph>

                    <ResourceList
                        fields={getFields()}
                        afterFiltersHtml={
                            <div className="ml-4 flex-shrink-0">
                                <Buttons
                                    buttons={
                                        [{
                                            label: translate("btn.Create_new"),
                                            type: "primary",
                                            onClick: hasEFSIntegration && handleToggleResourceDialog
                                        }]
                                    }
                                />
                            </div>
                        }
                        resource={resource}
                        isLoading={isLoading}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        onRefreshTable={() => fetchData(dispatch)}
                        listPath={getListPath()}
                        dispatch={dispatch}
                        query={getQuery()}

                        translate={translate}
                        selects={getSelects()}
                        onEdit={handleEditResourceClick}
                        maxHeightClass={"max-h-[calc(100vh-26.8rem)]"}
                    />

                </React.Fragment>
            ) : resource.isLoading ? (<LoaderLarge/>)
                : (<React.Fragment>
                        <Card>
                            <InfoParagraph type={'text-base'}>
                                {noEFSIntegrationMessage}
                            </InfoParagraph>
                        </Card>
                    </React.Fragment>
                )}


            <ModalSaveResource
                dialogWidth="max-w-3xl"
                fields={getCreateFields()}
                show={isResourceDialogOpen}
                title={translate("modal.heading.create_money_code")}
                onClose={() => {
                    handleToggleResourceDialog()
                }}

                addFieldContainerClass={"col-span-12"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={{
                    IssuedToID: {
                        api: 'api/' + Resources.Contacts,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.ContactID,
                            label: item.FirstName + ' ' + item.LastName
                        })
                    },
                    MoneyCodeTypeID: {
                        api: 'api/' + Resources.MoneyCodeType,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.MoneyCodeTypeID,
                            label: item.Reason
                        })
                    }
                }}
                translate={translate}
                onSubmit={(params) => {
                    if (params) {
                        params.WorkOrderID = ID;
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: getQuery(),
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            errorMessage: true, successMessage: translate('text.money_code_created')
                        }))
                        handleToggleResourceDialog()
                        isItemCreatedRef.current = true;
                    }
                }}
            />

            <ModalSaveResource
                show={isEditResourceDialogOpen}
                fields={getEditFields(selectedItem)}

                title={"Edit money code: " + selectedItem.Code}
                onClose={() => {
                    setIsEditResourceDialogOpen(false);
                    setSelectedItem({})
                }}
                onSubmit={(params) => {

                    if (!!params) {
                        params.WorkOrderID = ID;
                        params.MoneyCodeID = selectedItem.MoneyCodeID;
                        dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: getQuery(),
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            successMessage: translate("message.money_code_updated"),
                            errorMessage: true
                        }));


                        setSelectedItem(params); // in case of server error save form data
                        setIsEditResourceDialogOpen(false)

                    }
                }}
                addBodyClass={"pb-48 text-tm-gray-900"}
                isLoading={isLoading}
                metadata={{
                    ContactID: {
                        api: 'api/' + Resources.Contacts,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.ContactID,
                            label: item.FirstName + ' ' + item.LastName
                        })
                    },
                    MoneyCodeTypeID: {
                        api: 'api/' + Resources.MoneyCodeType,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.MoneyCodeTypeID,
                            label: item.Reason
                        })
                    }
                }}
                resource={resource}
                translate={translate}
            />

            <ModalDefault
                baseClass={'fixed inset-0 flex items-center justify-center'}
                show={isReceiveDialogOpen}
                widthClass={"max-w-3xl"}
                translate={translate}
                title={translate('Money code')}
                onClose={() => {
                    setIsReceiveDialogOpen(false)
                }}
                closeButtonLabel={translate('btn.close')}
            >
                <div className="flex items-center m-4">
                    <CopyToClipboardButton
                        clipboardText={fields.Code.value}
                        translate={translate}
                    />
                    <FieldText
                        addClass={'font-bold text-2xl rounded-md'}
                        {...fields.Code}
                    />
                </div>
            </ModalDefault>
        </React.Fragment>
    )
}
