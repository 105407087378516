import React, {useMemo, useState} from "react";
import {getDefaultQueryFields} from "../../../../util/util";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Resources from "../../../../data/services/resources";
import useQuery from "../../../../hooks/use-query";

import {classNames} from "../../../../common/util/util-helpers";

import {ArrowDownOnSquareIcon} from "@heroicons/react/24/outline";

import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS} from "../../../../util/util-constants";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import Buttons from "../../../../common/components/buttons";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import DropZoneAlt from "../../../../common/components/dropzone/drop-zone-alt";
import FileViewer from "../../../../common/components/file-viewer/components"
import DzItemFile from "../../../../common/components/dropzone/dz-item-file";


export default function WorkOrdersCreateImagesTab({
                                                      dataImages,
                                                      resource,
                                                      dispatch,
                                                      translate,
                                                      isLoading,
                                                      updateDataImages,
                                                      deleteDataImage
                                                  }) {

    /** Constants
     ================================================================= */
    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [resourceErrorMessage, setResourceErrorMessage] = useState(undefined);
    const [file, setFile] = useState([]);
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [documentPreviewModalOpen, setDocumentPreviewModalOpen] = useState(false);


    const getResourcePath = () => Resources.WorkOrdersImages;
    const getListPath = () => getResourcePath() + "_list"
    const getPrimaryField = () => "ImageID";
    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }


    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const getFields = () => {
        return {
            "documentName": new Field('documentName', '', [''], false, 'custom', {
                render: (item) => {

                    return (
                        <div>{item?.path}</div>
                    )
                }
            }),
            "ImageTypeID": new Field('ImageTypeID', '', [''], false, 'custom', {
                render: (item) => {

                    return (
                        ((Number(item?.fields?.ImageTypeID) === 1) &&
                            <div>Before</div>) || ((Number(item?.fields?.ImageTypeID) === 2) && <div>After</div>)

                    )
                },
                omitSort: true
            }),
            "Description": new Field('Description', '', [''], false, 'textarea', {
                render: (item) => {

                    return (
                        <div>{item?.fields?.Description}</div>
                    )
                }
            })


        }
    }

    const getFieldsDescription = () => {
        return {
            "Description": new Field('Description', '', ['empty'], false, "text", {addContainerClass: "col-span-full"}),
            "ImageTypeID": new Field('ImageTypeID', '', ['empty'], false, 'select', {addContainerClass: "col-span-full"})

        }
    }

    const descFields = useMemo(() => {
        return getFieldsDescription();
    }, [isResourceDialogOpen]);

    const handleToggleDocumentPreviewModal = (item = null) => {
        setSelectedItem(item)
        setDocumentPreviewModalOpen(!documentPreviewModalOpen)
    }
    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setResourceErrorMessage(false);
    }
    const handleSelectDocuments = (addedFile) => {
        let file = addedFile.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            errorMessage: '',
            fields: Object.assign({}, getFields())
        }))
        setFile(file);
    }

    const handleRemoveFile = () => {
        setFile([]);
    }

    const handleCloseDocumentModal = () => {
        setIsResourceDialogOpen(false);
        setFile([]);
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleDeleteDocumentClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteDocumentClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete <span className="font-bold text-red-600">{item.path}</span>?
            </span>,
            title: translate("text.confirm_delete_document"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteDocumentClick = (item) => {
        deleteDataImage(item);
        closeConfirmDialog();
    }

    const handleDownloadDocumentsClick = (item) => {
        const link = document.createElement("a");
        link.download = item.path;
        link.href = item.preview;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    function getSelects() {
        return {
            ImageTypeID: {
                1: "Before",
                2: "After"
            }
        }
    }


    /** Component Body
     ================================================================= */
    return (
        <div className="flex flex-col gap-2 mt-2">
            <ResourceList
                fields={getFields()}
                afterFiltersHtml={
                    <div className="ml-4 flex-shrink-0">
                        <Buttons
                            buttons={[
                                {
                                    label: translate("btn.UploadImage"),
                                    type: "primary",
                                    onClick: handleCreateResourceClick
                                }
                            ]}
                        />
                    </div>
                }

                dataImages={dataImages}
                isLoading={isLoading}
                queryFields={queryFields}
                setQueryFields={setQueryFields}
                listPath={getListPath()}
                dispatch={dispatch}
                query={query}

                translate={translate}
                selects={getSelects()}
                onDelete={handleDeleteDocumentClick}
                onView={handleToggleDocumentPreviewModal}
                actions={[{
                    icon: ArrowDownOnSquareIcon,
                    title: translate("btn.download"),
                    action: (item) => handleDownloadDocumentsClick(item)
                }]}
            />


            <ModalDefault
                show={documentPreviewModalOpen}
                title={translate("text.document_info")}
                widthClass={"max-w-3xl"}
                translate={translate}
                onClose={handleToggleDocumentPreviewModal}
                closeButtonLabel={translate("btn.close")}
                buttonLabel={translate("btn.download")}
                onButtonClick={() => {
                    handleDownloadDocumentsClick(selectedItem)
                }}


            >

                <center>
                    <FileViewer
                        fileType={selectedItem ? selectedItem?.path.split('.').pop() : ""}
                        filePath={selectedItem?.preview}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </center>

            </ModalDefault>

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />

            <ModalSaveResource
                dialogWidth="max-w-3xl"
                htmlBefore={
                    <div className={'relative'}>
                        <DropZoneAlt
                            translate={translate}
                            onAcceptFiles={handleSelectDocuments}
                            accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                            content={(isDragAccept, isDragReject) => {
                                return (
                                    <div className={classNames(
                                        ""
                                    )}>
                                        <div className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 min-h-[8rem] relative"
                                        )}>
                                            <React.Fragment>
                                                {!file.length && (
                                                    <div
                                                        className="absolute inset-0 flex items-center justify-center border-t-2 border-dashed border-tm-gray-300">
                                                        <div className="text-center">
                                                            <p className="text-lg">click here or drop files to
                                                                upload</p>
                                                            <p className="text-base text-tm-gray-500">(Max 10 files, 25
                                                                mb
                                                                per file, document and image files only)</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {!!file.length && (
                                                    <ul
                                                        className={classNames(
                                                            "rounded-lg mx-4",
                                                            !!file.length ? "divide-y divide-gray-200 border border-tm-gray-200 mt-8" : undefined
                                                        )}
                                                    >
                                                        {file.map((file, i) => {

                                                            return (
                                                                <DzItemFile
                                                                    key={i}
                                                                    index={i}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    translate={translate}
                                                                    description={""}
                                                                    name={file.name}
                                                                    onRemoveFile={handleRemoveFile}
                                                                />
                                                            )
                                                        })}
                                                    </ul>
                                                )}


                                            </React.Fragment>
                                        </div>

                                        <div
                                            className="px-4 cursor-pointer py-1 border-t border-sky-600 border-dotted bg-sky-600/5">
                                            Attach files by dragging and dropping them or <span
                                            className="text-sky-700 font-semibold">click here</span> to select them.
                                        </div>
                                    </div>
                                )
                            }}
                        /></div>}
                fields={descFields}
                htmlAfter={<div id={'error'}></div>}
                initialFocusID="ImageID"
                show={isResourceDialogOpen}
                title={translate("modal.heading.upload.document")}
                onClose={() => {
                    handleCloseDocumentModal()

                }}

                addFieldContainerClass={"col-span-12"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={getSelects()}
                translate={translate}
                onSubmit={(params) => {
                    if (params && !!file.length) {
                        const files = file.map((item) => Object.assign(item, {
                            fields: params
                        }))
                        updateDataImages(files);
                        handleCloseDocumentModal();
                    }

                }
                }
            />
        </div>
    )
}