import React, { useEffect, useRef } from "react";
import moment from "moment";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {classNames} from "../../../../common/util/util-helpers";

const NUMBER_OF_MINUTES_IN_A_DAY = 1440;

export default function CalendarDayView( props ) {
    const {days, selectedDay, onTimeClick, weekFormat, activeMonthDisplay, onSetActiveDay, setPrevMonth, setNextMonth, renderDayViewEvents, addContainerClass} = props;

    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)

    useEffect(() => {
        // Set the container scroll position based on the current time.
        const currentMinute = new Date().getHours() * 60

        container.current.scrollTop =
            ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
                currentMinute) /
            NUMBER_OF_MINUTES_IN_A_DAY
    }, [])

    const currentWeek = days.filter(it => it.isCurrentWeek);

    const handleDayClick = ( e ) => {
        const rect = e.target.getBoundingClientRect();
        const y = e.clientY - rect.top;  //y position within the element.
        const topMargin = 30;
        const sectionHeight = 56; // 30min a section
        const halfHour = 30;
        const minutes = Math.floor((y - topMargin) / sectionHeight) * halfHour;
        const date = moment(selectedDay.date, "YYYY-MM-DD").utc().startOf('day').add(1, 'days').add(minutes, 'minutes').format('YYYY-MM-DD HH:mm');
        onTimeClick(date);
    }

    return (
        <div className="flex flex-auto overflow-hidden bg-inverse">
            <div ref={container} className={classNames(
                addContainerClass,
                "flex flex-auto flex-col overflow-auto"
            )}>
                <div
                    ref={containerNav}
                    className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-inverse text-xs text-tm-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden text-sm leading-6"
                >
                    {currentWeek.map(( day, i ) => (
                        <button
                            onClick={() => onSetActiveDay(day.date)}
                            disabled={!day.isCurrentMonth}
                            key={day.day}
                            type="button"
                            className={classNames("flex flex-col items-center pt-2 pb-3", !day.isCurrentMonth ? "bg-tm-gray-100" : "")}
                        >
                            {weekFormat[i].abbr}

                            <span
                                className={classNames(
                                    "mt-1 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold",
                                    day.isSelected && !day.isToday ? "bg-tm-gray-900 text-inverse" : "",
                                    day.isSelected && day.isToday ? "bg-primary text-primary-contrast" : "",
                                    !!day.isSelected && !!day.isToday ? "text-tm-gray-900" : "",
                                    !day.isSelected && !!day.isToday ? "text-primary" : ""
                                )}
                            >
                                {day.day}
                          </span>
                        </button>
                    ))}
                </div>

                <div className="flex w-full flex-auto">
                    <div className="w-14 flex-none bg-inverse ring-1 ring-tm-gray-100"/>
                    <div className="grid flex-auto grid-cols-1 grid-rows-1">
                        {/* Horizontal lines */}
                        <div
                            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-tm-gray-300 divide-body"
                            style={{gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))'}}
                        >
                            <div ref={containerOffset} className="row-end-1 h-7"/>

                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    12AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    1AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    2AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    3AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    4AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    5AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    6AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    7AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    8AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    9AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    10AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    11AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    12PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    1PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    2PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    3PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    4PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    5PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    6PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    7PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    8PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    9PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    10PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-tm-gray-400">
                                    11PM
                                </div>
                            </div>
                            <div/>
                        </div>

                        {/* Events */}
                        <ol
                            className="relative col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                            style={{gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto'}}
                        >
                            <li
                                className="absolute inset-0 cursor-pointer"
                                onClick={handleDayClick}
                            />

                            {!!renderDayViewEvents && renderDayViewEvents(selectedDay?.events)}
                        </ol>
                    </div>
                </div>
            </div>

            <div className="hidden w-1/2 max-w-md flex-none border-l border-tm-gray-300 py-10 px-8 md:block">
                <div className="flex items-center text-center text-tm-gray-900">
                    <button
                        onClick={setPrevMonth}
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-tm-gray-400 hover:text-tm-gray-500"
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                    <div className="flex-auto font-semibold">{activeMonthDisplay}</div>
                    <button
                        onClick={setNextMonth}
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-tm-gray-400 hover:text-tm-gray-500"
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>
                <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-tm-gray-500">
                    {weekFormat.map(( weekDay, i ) => <div key={i}>{weekDay.abbr}</div>)}
                </div>
                <div
                    className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-tm-gray-200 text-sm shadow ring-1 ring-tm-gray-200">
                    {days.map(( day, dayIdx ) => (
                        <button
                            key={day.date}
                            type="button"
                            className={classNames(
                                'py-1.5 hover:bg-tm-gray-100 focus:z-10',
                                day.isCurrentMonth ? 'bg-inverse' : 'bg-tm-gray-50',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                day.isSelected && 'text-inverse',
                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-tm-gray-900',
                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-tm-gray-400',
                                day.isToday && !day.isSelected && 'text-primary',
                                dayIdx === 0 && 'rounded-tl-lg',
                                dayIdx === 6 && 'rounded-tr-lg',
                                dayIdx === days.length - 7 && 'rounded-bl-lg',
                                dayIdx === days.length - 1 && 'rounded-br-lg'
                            )}
                            onClick={() => onSetActiveDay(day.date)}
                        >
                            <time
                                dateTime={day.date}
                                className={classNames(
                                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                    day.isSelected && day.isToday && 'bg-primary text-primary-contrast',
                                    day.isSelected && !day.isToday && 'bg-tm-gray-900'
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}
