import { _ENV_MODE } from "./env";

export default class Cookie {
    /**
     * Get HTTP cookie
     * @param cookieName
     * @returns {*}
     */
    static getCookie(cookieName) {
        let name = cookieName + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * Set HTTP cookie
     * @param name
     * @param value
     * @param time
     * @param domain
     */
    static setCookie(name, value, time) {
        name = name + "_" + _ENV_MODE

        let r;
        if (time) {
            let t = new Date();
            t.setTime(t.getTime() + (time * 1000));
            r = '; expires=' + (t.toGMTString());
        } else {
            r = '';
        }

        document.cookie = name + '=' + value + r + ';domain=' + this.getDomainName() + ';path=/';
    }

    /**
     * Erase HTTP cookie
     * @param e
     */
    static eraseCookie(e) {
        Cookie.setCookie(e, '', -1);
    }

    static getDomainName() {
        const hostName = window.location.hostname.split(".");
        const domainName = hostName.slice(-2);
        return domainName.join(".");
    }
}