import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {getDefaultQueryFields, getUser, updateQuery} from "../../../../util/util";
import useQuery from "../../../../hooks/use-query";
import Resources from "../../../../data/services/resources";
import {getProp, openInNewTab} from "../../../../common/util/util-helpers";
import {createResource, deleteResource, getResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import CardTitle from "../../../../common/components/card/card-title";
import Card from "../../../../common/components/card";
import ResourceTableTopBar from "../../../../common/components/resource-table/table-components/resource-table-top-bar";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import FieldSearch from "../../../../common/components/fields/field-text/search";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ModalSaveResourceAuto from "../../../../common/components/modal/modal-save-resource/modal-save-resource-auto";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";

export default function WorkOrdersLinkedTab({ID, resource, dispatch, translate, isLoading}) {

    const getPrimaryField = () => "WorkOrderID";
    const getResourcePath = () => Resources.WorkOrdersLinked;
    const getListPath = () => getResourcePath() + "_list"

    const fetchData = (dispatch, query) => {
        dispatch(getResource({
            user: getUser(),
            resource: getResourcePath(),
            query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})})
        }))
    }

    const getFields = (item = {}) => {
        const fieldTemplates = {
            WorkOrderID: new Field('WorkOrderID', '', [''], false, 'hidden', {
                hideDialog: true,
                addContainerClass: "col-span-full"
            }),
            WorkOrderLinkID: new Field('WorkOrderLinkID', '', [''], false, 'select-search', {addContainerClass: "col-span-full"}),
            LinkedReason: new Field('LinkedReason', '', [''], false, 'text', {addContainerClass: "col-span-full"}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getSelects = (item = {}) => {
        return {
            WorkOrderLinkID: {
                api: 'api/' + Resources.WorkOrders,
                query: {},
                searchMap: (it) => ({
                    label: it.WorkOrder,
                    value: it.WorkOrderID,
                })
            }
        }
    }

    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }

    const data = getProp(resource.data, "list", []);
    const count = getProp(resource.data, "count", 0)

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields({}));
    const [selectedItem, setSelectedItem] = useState(undefined)
    const [resourceErrorMessage, setResourceErrorMessage] = useState(undefined);

    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [isDialogConfirmOpen, setIsDialogConfirmOpen] = useState(false);

    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
        fetchData(dispatch, FieldsManager.getFieldKeyValues(queryUpdate));
    }


    /** UI Events
     ================================================================= */
    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setResourceErrorMessage(false);
        setSelectedItem(undefined);
    }

    const handleDeleteLinkedWorkOrderClick = (item) => {
        setIsDialogConfirmOpen(true);
        setSelectedItem(item);
    }

    const handleViewLinkedWorkOrderClick = (item) => {
        openInNewTab('/work-orders/' + item.WorkOrderLinkID);
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData(dispatch, query);
    }, [])

    useEffect(() => {
        if (!!resource?.error) {
            setResourceErrorMessage(resource.errorMessage)
        }
    }, [resource?.error]);

    useEffect(() => {
        if (!!resource?.create?.id || !!resource.update) {
            setIsResourceDialogOpen(false);
        }
    }, [resource]);

    return (
        <React.Fragment>
            <Card
                bodyClass="rounded-md"
            >
                <ResourceTableTopBar
                    addClass="rounded-t-md ring-1 ring-black ring-opacity-5"
                >
                    <FieldSearch
                        addClass="form-control"
                        queryFields={queryFields}
                        handleQueryChange={handleQueryChange}
                        translate={translate}
                    />

                    <CardTitle
                        buttons={[
                            {
                                label: translate("btn.link_work_order"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />
                </ResourceTableTopBar>

                {(isLoading || !!count) && (
                    <ResourceTable
                        data={data}
                        fields={fields}
                        queryFields={queryFields}

                        options={{
                            style: {
                                stripedRows: true,
                                horizontalLines: true,
                                verticalLines: true,
                                floatingActions: true
                            }
                        }}

                        translate={translate}
                        isLoading={isLoading}

                        onRowClick={handleViewLinkedWorkOrderClick}
                        onDelete={handleDeleteLinkedWorkOrderClick}
                        onSortChange={(fieldName) => handleQueryChange("sortBy", fieldName)}
                    />
                )}


                <NoRecords
                    show={!isLoading && !count}
                    addClass="p-10"
                    title="No linked work orders"
                    text={"Link a work order by clicking on the button below."}
                    btnLabel={translate("btn.link_work_order")}
                    onBtnClick={handleCreateResourceClick}
                />

                <TableCardFooter>
                    {!(!data.length && !isLoading) && (
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            queryFields={queryFields}
                            handleQueryChange={handleQueryChange}
                            translate={translate}
                        />
                    )}
                </TableCardFooter>
            </Card>

            <ModalSaveResourceAuto
                fields={getFields(selectedItem)}
                initialFocusID="LinkedWorkOrder"
                show={isResourceDialogOpen}
                title={translate("modal_heading.create_linked_wo")}
                onClose={() => {
                    setIsResourceDialogOpen(false);
                }}
                onSubmit={(params) => {
                    if (!!params) {
                        params.WorkOrderID = ID;
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: query,
                            notificationMessage: translate("message.work_order_linked"),
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            piggyQuery: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})})
                        }));

                        setSelectedItem(params); // in case of server error save form data
                    }
                }}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={getSelects()}
                dialogErrorMessage={resourceErrorMessage}
                translate={translate}
            />

            <ModalConfirm
                show={isDialogConfirmOpen ?? false}
                type={"danger"}
                content={
                    <span>
                Are you sure you want to delete this linked work order?
                </span>
                }
                title={translate("text.confirm_delete_linked_wo")}
                onConfirm={() => {
                    dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign(query, {WorkOrderLinkedID: selectedItem.WorkOrderLinkedID}, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                        resource: getResourcePath(),
                        piggyResource: getResourcePath(),
                        successMessage: translate('message.linked_orders_deleted'),
                        errorMessage: true
                    }));
                    setIsDialogConfirmOpen(false);
                    setSelectedItem(undefined);
                }}
                closeButtonLabel={translate("btn.cancel")}
                buttonLabel={translate("btn.delete")}
                translate={translate}
                onClose={() => setIsDialogConfirmOpen(false)}
            />
        </React.Fragment>

    )
}
