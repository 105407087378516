import React, {useEffect, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getDefaultTableOptions, getUser, groupListBySCAC} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, getResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {classNames, getLookup, getProp, longTableColumn, resourceIsCreated} from "../../../common/util/util-helpers";
import {ChevronUpIcon as ChevronUpSolidIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {CREATE_PERM, READ_PERM} from "../../../util/util-constants";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge/index";
import Page from "../../../common/components/layout/layout-components/page";
import Tippy from "@tippyjs/react";
import {getUserDateTimeFormat, getUserTimezone, toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import TableReminder from "../../../common/components/resource-table/table-components/table-reminder";
import moment from 'moment-timezone';
import ModalDefault from "../../../common/components/modal/modal-default";
import FieldsForm from "../../../common/components/fields/fields-form";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import LocalStorage from "../../../common/util/localStorage";
import {LoaderLarge} from "../../../common/components/loader";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../common/util/util-consts";

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}
export default function WorkOrdersView({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const count = getProp(resource.data, "count", 0);
    const [selectedRows, setSelectedRows] = useState({});
    const workOrderStatuses = getLookup("WorkOrderStatus");

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {
        searchFields: JSON.stringify({
            WorkOrderStatusID: queryFields.WorkOrderStatusID?.value,
            RequestedServiceCategoryID: queryFields.RequestedServiceCategoryID?.value,
            TruckID: queryFields.TruckID?.value?.value,
            TrailerID: queryFields.TrailerID?.value?.value,
            AssignedVendorID: queryFields.AssignedVendorID?.value?.value,
            AssignedContactID: queryFields.AssignedContactID?.value?.value,
        })
    });

    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(getFields(), {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true
            }
        }, getListPath(), translate)
    );
    const [selectedOrganization, setSelectedOrganization] = useState({})
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);

    const [selectedReminder, setSelectedReminder] = useState(null);
    const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

    const [updateReminderFields, setUpdateReminderFields] = useState(() => getUpdateReminderFields())


    useEffect(() => {
        fetchData(dispatch, query)
    }, [queryFields]);

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: getUser(),
            resource: getResourcePath(),
            query: query
        }))
    }

    function handleReminderSubmit() {
        const params = FieldsManager.getFieldKeyValues(updateReminderFields);
        params.WorkOrderID = selectedReminder.WorkOrderID;
        params.id = selectedReminder.WorkOrderID;

        dispatch(createResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.WorkOrderNotes,
            piggyResource: getResourcePath(),
            query: query,
            successMessage: translate("text.reminder_updated"),
            errorMessage: true
        }))
    }

    /** UI events
     ================================================================= */
    const handleCreateResourceClick = () => {
        history.push('/work-orders-create/');
    }

    const handleEditResourceClick = (item) => {
        history.push('/work-orders/' + item.WorkOrderID);
    }

    const handleSelectRowClick = (item) => {
        const itemID = item.WorkOrderID

        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(resource.data, 'list', []))

        let selectedRowsClone = cloneDeep(selectedRows)

        if (!selectAll) {
            Object.assign(selectedRowsClone, data.reduce((memo, it) => {
                memo[it.WorkOrderID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsCloneKeys = Object.keys(selectedRowsClone)
            data.forEach(it => {
                if (selectedRowsCloneKeys.includes(it.WorkOrderID.toString())) {
                    delete selectedRowsClone[it.WorkOrderID]
                }
            })
        }

        setSelectedRows(selectedRowsClone)
    }

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (item) => {
        setSelectedOrganization(item)
        setIsOrganizationInfoDialogOpen(true)
    }

    const handleReminderUpdateClick = (item) => {
        setSelectedReminder(item);
        setIsReminderModalOpen(true);
    }

    const handleReminderUpdateModalClose = () => {
        setSelectedReminder(null);
        setIsReminderModalOpen(false);
    }

    function handleReminderInputChange(name, value) {
        let reminderUpdate = Object.assign({}, updateReminderFields);
        reminderUpdate = FieldsManager.updateField(reminderUpdate, name, value)
        setUpdateReminderFields(reminderUpdate);
    }

    /** Helpers
     ================================================================= */
    function getFields(item = {}) {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text'),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewContactClick(item.ChargeContactID)}
                        >
                            {item.ChargeContact}
                        </CellButton>
                    }
                    if (item.ChargeOrganizationID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewOrganizationClick(item.ChargeOrganizationID)}
                        >
                            {item.ChargeOrganization}
                        </CellButton>
                    }
                }
            }),
            UpdateReminderInterval: new Field('UpdateReminderInterval', "", [''], false, 'custom', {
                disableColumnFilter: true,
                // hideTable: true,
                label: "StopUpdateReminder",
                render: (item) => {
                    if (!item.UpdateReminderInterval) {
                        return <button onClick={
                            (e) => {
                                e.stopPropagation();
                                handleReminderUpdateClick(item)
                            }}
                                       className="text-tm-gray-500 font-semibold hover:text-primary hover:bg-tm-gray-100 p-1.5 rounded-btn">
                            No reminder set
                        </button>
                    }

                    const greenZoneDiff = item.UpdateReminderInterval * 0.8;
                    const currentDateTime = moment()
                        .tz(getUserTimezone())
                        .subtract(item?.UpdateReminderInterval ?? 0, 'minutes')
                        .format(DEFAULT_DATABASE_DATETIME_FORMAT)
                    const utcDate = moment(toFrontDateTimeFromUTC(item.LastNoteDateTime), getUserDateTimeFormat())
                        .format(DEFAULT_DATABASE_DATETIME_FORMAT);

                    const intervalDiff = moment(utcDate).diff(currentDateTime, 'minutes');

                    //  const intervalDiff = moment(currentDateTime).diff(placeholderDate, 'minutes');

                    let type = "warning"
                    let reminderText = translate("text.reminderToNote", [intervalDiff]);

                    if (intervalDiff < 0) {
                        type = "danger"
                        reminderText = translate("text.reminderDueNote", [Math.abs(intervalDiff)])
                    } else if (intervalDiff >= greenZoneDiff) {
                        type = "ok"
                    }

                    return (
                        <TableReminder
                            onClick={() => handleReminderUpdateClick(item)}
                            type={type}
                            content={reminderText}
                            item={item}
                        />
                    )
                }
            }),
            Title: new Field('Title', "", [''], false, 'custom', {
                disableColumnFilter: true,
                label: "LastStopUpdate",
                render: (item) => {
                    if (!item.LastTitleNotes) {
                        return null;
                    }
                    if (item.LastNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastNotes}</span>}
                            >
                                <span>
                                    {item.LastTitleNotes} ({toFrontDateTimeFromUTC(item.LastNoteDateTime)})
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.Title}
                        </span>
                    )
                }
            }),
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.InitialComplaint)}</div>
                )
            }),
            Notes: new Field('Notes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getUpdateReminderFields(item = {}) {
        const fieldTemplates = {
            Title: new Field('Title', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
            Notes: new Field('Notes', '', [''], false, 'text', {addContainerClass: "col-span-12"}),
            IsUpdateReminderOn: new Field('IsUpdateReminderOn', '', [''], false, 'switch', {
                addContainerClass: "px-4 col-span-7 flex items-center hover:bg-sky-600/10 rounded-xl"
            }),
            UpdateReminderInterval: new Field('UpdateReminderInterval', '', [item?.IsUpdateReminderOn ? 'empty' : ''], false, 'integer', {
                addContainerClass: "col-span-5",
                label: translate("field.set_next_reminderin")
            })
        }

        const filledFields = fillFieldsFromData(fieldTemplates, item);
        filledFields.Notes.value = "";
        return filledFields;
    }

    useEffect(() => {
        if (selectedReminder) {
            setUpdateReminderFields(getUpdateReminderFields(selectedReminder))
        }
    }, [selectedReminder]);

    useEffect(() => {
        if (resourceIsCreated(resource)) {
            setIsReminderModalOpen(false);
        }
    }, [resource])

    updateReminderFields.IsUpdateReminderOn.metadata.htmlAfter = (it) =>
        <FieldSwitchLabel
            onClick={() => handleReminderInputChange("IsUpdateReminderOn", !it.value)}
            label={translate("field.IsUpdateReminderOn")}
            note={translate("text.StopUpdateReminderInfo")}
        />

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                customPageTitle={"Work Orders"}
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page_title.work-orders")}
                        buttons={[
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick,
                                isHidden: !checkPerm(getResourcePath(), CREATE_PERM)
                            }
                        ]}
                    />

                    <ResourceList
                        fields={getFields()}

                        resource={resource}
                        // fetchData={fetchData}
                        isLoading={isLoading}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        listPath={getListPath()}
                        dispatch={dispatch}
                        query={query}
                        onSelectRow={handleSelectRowClick}
                        onSelectAllClick={handleSelectAllClick}
                        selectedRows={selectedRows}
                        onRefreshTable={fetchData}
                        translate={translate}
                        // onMount={fetchData}
                        selects={getSelects()}
                        tableKey={"WorkOrderID"}
                        onRowClick={handleEditResourceClick}
                        // onView
                        // onEdit
                    />

                    <TableBulkActions
                        selectedRows={selectedRows}
                        tableKey="WorkOrderID"
                        fields={getFields()}
                        translate={translate}
                        options={tableOptions}
                        setSelectedRows={setSelectedRows}
                        onSelectAllClick={handleSelectAllClick}
                    >
                        <div className='flex items-center h-8'>
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                <div className='flex gap-x-1 pr-2'>


                                    <div className='flex gap-x-1 px-2'>
                                        <TableTagManager
                                            rowKey={'WorkOrderID'}
                                            btnIconClass='w-5 h-5'
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            selectedRows={selectedRows}
                                            resourceName={Resources.ColorTagWorkOrders}
                                            piggyResourceName={getResourcePath()}
                                            query={query}
                                            onAfterTagRows={() => {
                                                setSelectedRows({})
                                            }}
                                            translate={translate}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </TableBulkActions>

                    {(isOrganizationInfoDialogOpen &&
                        <OrganizationInfoDialog
                            show={isOrganizationInfoDialogOpen}
                            translate={translate}
                            organizationID={selectedOrganization}
                            handleClose={() => setIsOrganizationInfoDialogOpen(false)}
                        />
                    )}
                </Page>
            </Layout>

            <ModalDefault
                show={isReminderModalOpen}
                widthClass={"max-w-3xl"}
                title={translate("text.wo_reminder_update")}

                onButtonClick={handleReminderSubmit}
                buttonLabel={translate('btn.apply')}

                closeButtonLabel={translate('btn.Cancel')}
                onClose={handleReminderUpdateModalClose}
            >
                <>
                    {isLoading && (
                        <LoaderLarge/>
                    )}
                    <div className="p-6 grid grid-cols-12 gap-4">
                        <FieldsForm
                            isLoading={isLoading}
                            fieldsState={updateReminderFields}
                            onChange={handleReminderInputChange}
                            translate={translate}
                        />
                    </div>
                </>
            </ModalDefault>
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "WorkOrderID";
const getResourcePath = () => Resources.WorkOrders;

const getListPath = () => getResourcePath() + "_list"


const getQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields(getPrimaryField(), translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    hasActiveBadge: true,
                }),
                WorkOrderStatusID: new Field('WorkOrderStatusID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                TruckID: new Field('TruckID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                TrailerID: new Field('TrailerID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                AssignedVendorID: new Field('AssignedVendorID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                AssignedContactID: new Field('AssignedContactID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
            }
        )
    )
}


function getSelects() {
    return {
        AssignedContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        RequesterID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        AssignedVendorID: {
            api: 'api/' + Resources.Vendors,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.VendorID,
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Truck')
            }
        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Trailer')
            }
        },
        CustomCodeID: {
            api: 'api/' + Resources.WorkOrdersCustomCodes,
            query: {},
            searchMap: (it) => ({
                label: it.CustomCode,
                value: it.CustomCodeID,
            })
        },
        VMRSCode: {
            api: 'api/' + Resources.WorkOrdersVMRSCode,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.value,
                    value: it.value,
                })
            }
        },
        WorkOrderStatusID: getLookup('WorkOrderStatus'),
        RequestedServiceCategoryID: getLookup('RequestedServiceCategory'),
    }
}
