import React, {useEffect, useState} from "react";
import { genericMoneyFormatter } from "../../../../common/util/util-vanilla";
import {LoaderSmall} from "../../../../common/components/loader";
import {classNames} from "../../../../common/util/util-helpers";
import Tippy from "@tippyjs/react";

export default function WorkOrdersLaborPartsPrices(
    {isResourceLoading, isSecondResourceLoading, totalPriceParts, totalPriceLabor, shopSupplies, dataObject, dataMiscTotal}
) {

    const [surchargeAmount, setSurchargeAmount] = useState(dataObject?.SurchargeAmount)

    useEffect(() => {
               setSurchargeAmount(null);
    }, [totalPriceLabor, totalPriceParts]);

    useEffect(() => {
        setSurchargeAmount(dataObject?.SurchargeAmount)
    }, [dataObject]);

    const ShopSuppliesTotalAmount = () => {
        switch (shopSupplies?.ShopSuppliesSurchargeTypeID) {
            case 1:
                return 0;
            case 2:
                return totalPriceLabor * (shopSupplies?.ShopSuppliesAmount - 1).toFixed(2);
            case 3:
                return (totalPriceLabor + totalPriceParts) * (shopSupplies?.ShopSuppliesAmount - 1).toFixed(2);
            default:
                return 0;
        }
    }

    const TotalAmountWithSupplies = () => {
        let suppliesAmount = ShopSuppliesTotalAmount()

        if (shopSupplies.ShopSuppliesMinAmount && suppliesAmount < shopSupplies.ShopSuppliesMinAmount && totalPriceLabor) {
            suppliesAmount = shopSupplies.ShopSuppliesMinAmount
        }

        if (shopSupplies.ShopSuppliesMaxAmount && suppliesAmount > shopSupplies.ShopSuppliesMaxAmount) {
            suppliesAmount = shopSupplies.ShopSuppliesMaxAmount
        }

        return  (totalPriceParts + totalPriceLabor) + suppliesAmount
    }

    const SurchargeType = (type) => {

        switch (type) {
            case 1:
                return "No surcharge"
            case 2:
                return "Labor only"
            case 3:
                return "Labor and parts"
            default:
                return "No surcharge"
        }
    }
    return (
        <div
            className={classNames("grid grid-cols-1 divide-y divide-tm-gray-200 border border-tm-gray-200 bg-inverse sm:divide-y-0 sm:divide-x rounded-lg shadow-card h-16", shopSupplies ? "sm:grid-cols-5" : "sm:grid-cols-3")}
        >
            <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                {!isResourceLoading && (
                    <React.Fragment>
                        <p>Parts: {" "}</p>
                        <span
                            className="font-semibold tracking-wide text-tm-gray-900"
                        >
                                    {genericMoneyFormatter(totalPriceParts)}
                                </span>
                    </React.Fragment>
                )}

                {isResourceLoading && (
                    <div className="flex w-full justify-center">
                        <LoaderSmall disableContainer/>
                    </div>
                )}
            </div>

            <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                {!isSecondResourceLoading && (
                    <React.Fragment>
                        <p>Labor: {" "}</p>
                        <span
                            className="font-semibold tracking-wide text-tm-gray-900"
                        >
                                    {genericMoneyFormatter(totalPriceLabor)}
                                </span>
                    </React.Fragment>
                )}

                {isSecondResourceLoading && (
                    <div className="flex w-full justify-center">
                        <LoaderSmall disableContainer/>
                    </div>
                )}
            </div>

            {!shopSupplies && (
                <div className="px-6 py-1 text-center text-lg font-bold text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && (
                        <React.Fragment>
                            <p>Total: {" "}</p>
                            <span
                                className="font-semibold tracking-wide text-tm-gray-900"
                            >
                                    {genericMoneyFormatter(totalPriceLabor + totalPriceParts)}
                                </span>
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            )}

            {/*Shop supplies*/}
            <Tippy content={<div>
                <p>{`Supplies surcharge type ${SurchargeType(shopSupplies?.ShopSuppliesSurchargeTypeID) ?? ''} (${(Number(shopSupplies?.ShopSuppliesAmount - 1) * 100).toFixed(2)}%)`}</p>
                <p>{(shopSupplies?.ShopSuppliesMaxAmount ? `No more than ${shopSupplies?.ShopSuppliesMaxAmount}$` : '')}</p>
                <p>{(shopSupplies?.ShopSuppliesMinAmount ? `No less than ${shopSupplies?.ShopSuppliesMinAmount}$` : '')}</p>
                <p>{}</p></div>}>
                <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && shopSupplies && (
                        <React.Fragment>
                            <p>Shop supplies: {" "}</p>
                            {!!surchargeAmount ? (
                                    <span
                                        className="font-semibold tracking-wide text-tm-gray-900"
                                    >
                                        {genericMoneyFormatter(surchargeAmount)}
                                    </span>
                                ) :

                                (<span
                                    className="font-semibold tracking-wide text-tm-gray-900"
                                >
                                    {!!shopSupplies.ShopSuppliesMinAmount && !!(totalPriceLabor || (shopSupplies.ShopSuppliesSurchargeTypeID === 3 && totalPriceParts)) && ((ShopSuppliesTotalAmount()) < shopSupplies.ShopSuppliesMinAmount) &&
                                        genericMoneyFormatter(shopSupplies.ShopSuppliesMinAmount)
                                    }

                                    {(!!shopSupplies.ShopSuppliesMinAmount && !!shopSupplies.ShopSuppliesMaxAmount) && !!(totalPriceLabor || totalPriceParts) && (((ShopSuppliesTotalAmount()) > shopSupplies.ShopSuppliesMinAmount) && ((ShopSuppliesTotalAmount()) < shopSupplies.ShopSuppliesMaxAmount)) &&
                                        genericMoneyFormatter(ShopSuppliesTotalAmount())
                                    }

                                    {(!!shopSupplies.ShopSuppliesMinAmount && !shopSupplies.ShopSuppliesMaxAmount) && !!(totalPriceLabor || totalPriceParts) && ((ShopSuppliesTotalAmount()) > shopSupplies.ShopSuppliesMinAmount) &&
                                        genericMoneyFormatter(ShopSuppliesTotalAmount())
                                    }


                                    {(!shopSupplies.ShopSuppliesMinAmount && !!shopSupplies.ShopSuppliesMaxAmount) && !!(totalPriceLabor || totalPriceParts) && ((ShopSuppliesTotalAmount()) < shopSupplies.ShopSuppliesMaxAmount) &&
                                        genericMoneyFormatter(ShopSuppliesTotalAmount())
                                    }

                                    {(!shopSupplies.ShopSuppliesMinAmount && !shopSupplies.ShopSuppliesMaxAmount) && !!(totalPriceLabor || totalPriceParts) &&
                                        genericMoneyFormatter(ShopSuppliesTotalAmount())
                                    }

                                    {!!shopSupplies.ShopSuppliesMaxAmount && !!(totalPriceLabor || totalPriceParts) && ((ShopSuppliesTotalAmount()) > shopSupplies.ShopSuppliesMaxAmount) &&
                                        genericMoneyFormatter(shopSupplies.ShopSuppliesMaxAmount)
                                    }


                                    {!totalPriceLabor && !totalPriceParts && '$0.00'}

                                        </span>)}
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            </Tippy>

            {!!dataObject && (
                <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && (
                        <React.Fragment>
                            <p>Misc. charges: {" "}</p>
                            <span
                                className="font-semibold tracking-wide text-tm-gray-900"
                            >
                                    {genericMoneyFormatter(dataMiscTotal)}
                                </span>
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            )}

            {!!shopSupplies && (
                <div className="px-6 py-1 text-center text-lg font-bold text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && (
                        <React.Fragment>
                            <p>Total: {" "}</p>
                            <span
                                className="font-semibold tracking-wide text-tm-gray-900"
                            >
                                    {genericMoneyFormatter(TotalAmountWithSupplies())}
                                </span>
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            )}
        </div>

    )
}