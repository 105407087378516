import React, {useEffect, useMemo, useState} from "react";
import {getDefaultQueryFields, getJWT, getUser} from "../../../../util/util";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Resources from "../../../../data/services/resources";
import useQuery from "../../../../hooks/use-query";
import {classNames} from "../../../../common/util/util-helpers";
import LocalStorage from "../../../../util/localStorage";
import {ArrowDownOnSquareIcon} from "@heroicons/react/24/outline";
import {deleteDocument, download, uploadDocument} from "../../../../data/actions/download";
import Env from "../../../../util/env";
import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS} from "../../../../util/util-constants";
import {render} from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {getResource} from "../../../../data/actions/resource";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import Buttons from "../../../../common/components/buttons";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import DropZoneAlt from "../../../../common/components/dropzone/drop-zone-alt";
import FileViewer from "../../../../common/components/file-viewer/components"
import DzItemFile from "../../../../common/components/dropzone/dz-item-file";

export default function WorkOrdersImagesTab({ID, translate}) {
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const downloadResource = useSelector((state) => state.download);

    const isLoading = resource.isLoading || downloadResource.isLoading

    /** Constants
     ================================================================= */
    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [resourceErrorMessage, setResourceErrorMessage] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogPreview, setDialogPreview] = useState({});
    const [documentPreviewModalOpen, setDocumentPreviewModalOpen] = useState(false);

    const getResourcePath = () => Resources.WorkOrdersImages;
    const getListPath = () => getResourcePath() + "_list"
    const getPrimaryField = () => "ImageID";
    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }

    const fetchData = (dispatch, query) => {
        dispatch(getResource({
            user: getUser(),
            resource: getResourcePath(),
            query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})})
        }));
    }

    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const getFields = () => {
        return {
            "DocumentName": new Field('DocumentName', '', [''], false),
            "Description": new Field('Description', '', [''], false),
            "CreateUpdateDate": new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
            "UploadedBy": new Field('UploadedBy', '', [''], false, 'custom', {
                render: (item) => {
                    return (<div>{item.FirstName} {item.LastName}</div>)
                },
                omitSort: true
            })
            , "ImageTypeID": new Field('ImageTypeID', '', [''], false, 'custom', {
                render: (item) => {

                    return (
                        ((item.ImageTypeID === 1) && <div>Before</div>) || ((item.ImageTypeID === 2) &&
                            <div>After</div>)

                    )
                },
                omitSort: true
            }),
        }
    }

    const getFieldsDescription = () => {
        return {
            "Description": new Field('Description', '', ['empty'], false, "text", {addContainerClass: "col-span-full"}),
            "ImageTypeID": new Field('ImageTypeID', '', ['empty'], false, 'select', {addContainerClass: "col-span-full"})

        }
    }

    const descFields = useMemo(() => {
        return getFieldsDescription();
    }, [isResourceDialogOpen]);

    const handleToggleDocumentPreviewModal = (item = null) => {
        setSelectedItem(item)
        setDocumentPreviewModalOpen(!documentPreviewModalOpen)
    }
    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setResourceErrorMessage(false);
    }
    const handleSelectDocuments = (addedFiles) => {
        setFiles(addedFiles);
    }

    const handleRemoveFile = () => {
        setFiles([]);
    }

    const handleCloseDocumentModal = () => {
        setIsResourceDialogOpen(false);
        setFiles([])
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleDeleteDocumentClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteDocumentClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete <span className="font-bold text-red-600">{item.DocumentName}</span>?
            </span>,
            title: translate("text.confirm_delete_document"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteDocumentClick = (item) => {
        dispatch(deleteDocument({
            user: LocalStorage.get('user'),
            query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})}),
            params: Object.assign({ImageID: item.ImageID}),
            notificationMessage: translate('message.document_delete'),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate('message.document_delete'),
            errorMessage: true,

        }));

        closeConfirmDialog();
    }

    const handleDownloadDocumentsClick = (item) => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrdersImagesSingle,
            query: Object.assign({}, {
                ImageID: item.ImageID,
                name: item.DocumentName
            }),
            notificationMessage: 'Successfully downloaded document!'
        }))

    }

    useEffect(() => {
        fetchData(dispatch, query);
    }, [queryFields]);


    function getSelects() {
        return {
            ImageTypeID: {
                1: "Before",
                2: "After"
            }
        }
    }

    function uploadErrorMessage() {
        render(<InfoParagraph type={'danger'} message={"You must upload file"}/>, document.getElementById('error'));

    }

    /** Component Body
     ================================================================= */
    return (
        <div className="flex flex-col gap-2 mt-2">
            <ResourceList
                fields={getFields()}
                afterFiltersHtml={
                    <div className="ml-4 flex-shrink-0">
                        <Buttons
                            buttons={
                                [{
                                    label: translate("btn.UploadImage"),
                                    type: "primary",
                                    onClick: handleCreateResourceClick
                                }]
                            }
                        />
                    </div>
                }
                resource={resource}
                // fetchData={() => fetchData(dispatch, query)}
                onRefreshTable={() => fetchData(dispatch, query)}
                isLoading={isLoading}
                queryFields={queryFields}
                setQueryFields={setQueryFields}
                listPath={getListPath()}
                dispatch={dispatch}
                query={query}

                translate={translate}

                selects={getSelects()}
                onDelete={handleDeleteDocumentClick}
                onView={handleToggleDocumentPreviewModal}
                actions={[{
                    icon: ArrowDownOnSquareIcon,
                    title: translate("btn.download"),
                    action: (item) => handleDownloadDocumentsClick(item)
                }]}
            />

            <ModalDefault
                show={documentPreviewModalOpen}
                title={translate("text.document_info")}
                widthClass={"max-w-3xl"}
                translate={translate}
                onClose={handleToggleDocumentPreviewModal}
                closeButtonLabel={translate("btn.close")}
                buttonLabel={translate("btn.download")}
                onButtonClick={() => {
                    handleDownloadDocumentsClick(selectedItem)
                }}
            >

                <center>
                    <FileViewer
                        fileType={selectedItem?.PhysicalLocation ? selectedItem?.PhysicalLocation.split('.').pop() : ""}
                        filePath={Env.getApiUrl("api/" + Resources.WorkOrdersImagesSingle, Object.assign({}, {
                            ImageID: selectedItem?.ImageID,
                            token: getJWT().access_token,
                            type: 1
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </center>
            </ModalDefault>

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />

            <ModalSaveResource
                dialogWidth="max-w-3xl"
                htmlBefore={
                    <div className={'relative'}>
                        <DropZoneAlt
                            translate={translate}
                            onAcceptFiles={handleSelectDocuments}
                            accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                            content={(isDragAccept, isDragReject) => {
                                return (
                                    <div className={classNames(
                                        ""
                                    )}>
                                        <div className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 min-h-[8rem] relative"
                                        )}>
                                            <React.Fragment>
                                                {!files.length && (
                                                    <div
                                                        className="absolute inset-0 flex items-center justify-center border-t-2 border-dashed border-tm-gray-300">
                                                        <div className="text-center">
                                                            <p className="text-lg">click here or drop files to
                                                                upload</p>
                                                            <p className="text-base text-tm-gray-500">(Max 10 files, 25
                                                                mb
                                                                per file, document and image files only)</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {!!files.length && (
                                                    <ul
                                                        className={classNames(
                                                            "rounded-lg mx-4",
                                                            !!files.length ? "divide-y divide-gray-200 border border-tm-gray-200 mt-8" : undefined
                                                        )}
                                                    >
                                                        {files.map((file, i) => {

                                                            return (
                                                                <DzItemFile
                                                                    key={i}
                                                                    index={i}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    translate={translate}
                                                                    description={""}
                                                                    name={file.name}
                                                                    onRemoveFile={handleRemoveFile}
                                                                />
                                                            )
                                                        })}
                                                    </ul>
                                                )}


                                            </React.Fragment>
                                        </div>

                                        <div
                                            className="px-4 cursor-pointer py-1 border-t border-sky-600 border-dotted bg-sky-600/5">
                                            Attach files by dragging and dropping them or <span
                                            className="text-sky-700 font-semibold">click here</span> to select them.
                                        </div>
                                    </div>
                                )
                            }}
                        /></div>}
                fields={descFields}
                htmlAfter={<div id={'error'}></div>}
                initialFocusID="ImageID"
                show={isResourceDialogOpen}
                title={translate("modal.heading.upload.document")}
                onClose={() => {
                    handleCloseDocumentModal()

                }}

                addFieldContainerClass={"col-span-12"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={getSelects()}
                translate={translate}
                onSubmit={(params) => {
                    if (params && !!files.length) {
                        dispatch(uploadDocument({
                            user: getUser(),
                            params: {
                                Description: params.Description,
                                WorkOrderID: ID,
                                ImageTypeID: params.ImageTypeID
                            },
                            resource: getResourcePath(),
                            query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                            files: files,
                            piggyResource: getResourcePath()
                        }))
                        handleCloseDocumentModal()
                    }
                    if (!files.length) {
                        uploadErrorMessage()
                    }
                }
                }
            />
        </div>
    )
}
