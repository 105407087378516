import React, {useEffect, useState} from "react";
import {excludeFields, fieldsToHtml, includeFields} from "../../../../util/util-fields";
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    saveTableColumns,
    updateQuery
} from "../../../../util/util-helpers";
import TableSettingsPopOver from "../../../resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../resource-table/table-components/active-filters";
import TableCard from "../../../resource-table/table-components/table-card";
import ResourceTableTopBar from "../../../resource-table/table-components/resource-table-top-bar";
import TableFilters from "../../../resource-table/table-components/table-filters";
import ResourceTable from "../../../resource-table";
import NoRecords from "../../../no-records-found/no-records";
import Pagination from "../../../resource-table/table-components/pagination";
import TableCardFooter from "../../../resource-table/table-components/table-card-footer";
import TableOptionsDialog from "../../../resource-table/table-components/table-options-dialog";
import {getProp} from "../../../../util/util-helpers";
import {FieldsManager} from "../../../../../data/services/fields";
import {cloneDeep} from "../../../../util/util-vanilla";


export default function ResourceList({
                                         fields,
                                         resource,
                                         translate,

                                         fetchData,
                                         isLoading,
                                         queryFields,
                                         setQueryFields,
                                         query,
                                         dispatch,
                                         listPath,

                                         onMount,
                                         selects,
                                         onRowClick,
                                         onView,
                                         onEdit,
                                         onDelete,
                                         onRestore,
                                         actions,
                                         hasViewPerm,
                                         hasEditPerm,
                                         hasDeletePerm,
                                         hasRestorePerm,
                                         onRefreshTable,
                                         dataImages,
                                         maxHeightClass,
                                         afterFiltersHtml,
                                         beforeSettingsHtml,
                                         allFiltersClass = "hidden",
                                         excludeActiveFilters,
                                         tableTitle,
                                         tableDefaults = {
                                             behaviour: {
                                                 rowSelect: true,
                                                 hasMenu: true,
                                                 canAdjustWidth: true
                                             },
                                             columns: {
                                                 RentalStatusID: {
                                                     minWidth: 200
                                                 }
                                             },
                                             style: {
                                                 condensed: true,
                                                 isGPUAccelerated: true
                                             }
                                         },
                                         onSelectRow,
                                         selectedRows,
                                         tableKey,
                                         children,
                                         onSelectAllClick
                                     }) {

    /** Constants
     ================================================================= */
    const data = dataImages ?? getProp(resource, "data.list", []);
    const count = dataImages?.length ?? getProp(resource, "data.count", 0)
    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false)

    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, {}, name, value);

        if (name === 'query') {
            queryUpdate.offset.value = 0;
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'archived') {
            queryUpdate.offset.value = 0;
        }

        if (name === 'StatusID') {
            queryUpdate.offset.value = 0;
        }
        if (name === 'TrailerOwnershipTypeID') {
            queryUpdate.offset.value = 0;
        }
        if (name === 'NotSoldRetired') {
            queryUpdate.offset.value = 0;
        }

        if (name === "RentContactID") {
            queryUpdate.RentOrganizationID.value = null;
        }

        if (name === "RentOrganizationID") {
            queryUpdate.RentContactID.value = null;
        }

        if (name === "PayContactID") {
            queryUpdate.PayOrganizationID.value = null;
        }

        if (name === "PayOrganizationID") {
            queryUpdate.PayContactID.value = null;
        }

        setQueryFields(queryUpdate);

        if (fetchData) {
            fetchData(dispatch, FieldsManager.getFieldKeyValues(queryUpdate));
        }
    }

    /** Data events
     ================================================================= */
    function handleFilterInputChange(name, value) {
        setQueryFields(Object.assign({}, FieldsManager.updateField(queryFields, name, value)));
    }

    function handleClearFiltersClick(excludeAdditional = []) {
        const queryFieldsCLone = Object.assign({}, queryFields);
        const excludedFields = ['limit', 'offset', 'sort', 'sortBy'];

        Object.values(queryFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFieldsCLone, it.name, '')
        });

        setQueryFields(queryFieldsCLone);
    }

    /** UI events
     ================================================================= */
    let checkboxFilters = Object.values(queryFields).reduce((memo, it) => {
        if (it.type === 'checkbox') {
            memo.push(it.name);
        }
        return memo;
    }, []);

    let otherFilters = Object.values(queryFields).reduce((memo, it) => {
        if (it.type !== 'checkbox' && it.name !== 'limit' && it.name !== 'query') {
            memo.push(it.name);
        }
        return memo;
    }, []);

    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(fields, tableDefaults, listPath, translate)
    );

    const handleSetTableOptions = (options) => {
        setTableOptions(options);
        if (listPath) {
            saveTableColumns(listPath, options);
        }
    }

    const setTableColumnOptions = (columns) => {
        let tableOptionsUpdate = cloneDeep(tableOptions)

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsUpdate)
        setIsColumnsDialogVisible(false)
        saveTableColumns(listPath, tableOptionsUpdate)
    }

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(fields, {}, translate)
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (typeof onMount === "function") {
            onMount(dispatch, query);
        }
    }, [])

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <div className="flex items-center">
                <div className={'flex-shrink-0 text-lg mx-2'}>
                    {tableTitle}
                </div>

                <ActiveFilters
                    excludedFields={excludeActiveFilters}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    filterFields={queryFields}
                    handleQueryChange={handleQueryChange}
                    translate={translate}
                />

                {beforeSettingsHtml}

                <div className="ml-auto flex items-center">
                    <TableSettingsPopOver
                        options={tableOptions}
                        setOptions={setTableOptions}
                        toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                        translate={translate}
                    />

                    {afterFiltersHtml}
                </div>
            </div>

            <TableCard>
                <ResourceTableTopBar addClass={'inline-block w-full'}>
                    <TableFilters
                        hideLimit
                        selects={selects}
                        addClass={"items-center gap-x-2 items-center text-tm-gray-900 inline-block w-full"}
                        className={"flex gap-2"}
                        filterFields={queryFields}
                        handleInputChange={handleQueryChange}
                        translate={translate}
                        onRefreshTable={onRefreshTable}
                    />
                </ResourceTableTopBar>

                <ResourceTable
                    addTableClass="z-0 border-x border-tm-gray-300"
                    data={data}
                    fields={fields}
                    maxHeightClass={maxHeightClass}
                    queryFields={queryFields}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}
                    onSelectAllClick={onSelectAllClick}
                    onSelectRow={onSelectRow}
                    onSortChange={(fieldName) => handleQueryChange("sortBy", fieldName)}
                    saveTableOptions={handleSetTableOptions}
                    onRowClick={onRowClick}
                    selectedRows={selectedRows}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onRestore={onRestore}
                    actions={actions}
                    hasViewPerm={hasViewPerm}
                    hasEditPerm={hasEditPerm}
                    hasDeletePerm={hasDeletePerm}
                    hasRestorePerm={hasRestorePerm}
                    tableKey={tableKey}
                />

                {children}

                <NoRecords
                    show={!isLoading && !count}
                    addClass="p-10"
                    title={translate("No matching records found")}
                />

                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={handleQueryChange}
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableCardFooter>
            </TableCard>

            <TableOptionsDialog
                show={isColumnsDialogVisible}
                pagePath={listPath}
                columns={tableOptions.columns}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />
        </React.Fragment>
    )
}

