import Resources from "../../../../data/services/resources";
import Env from "../../../../util/env";
import {getJWT} from "../../../../common/util/util-auth";
import EmailDocumentDialog from "../../../../common/components/modal/modal-email-documents/modal-email-document";
import React, {useState} from "react";
import {Field} from "../../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import {getResource} from '../../../../data/actions/resource'
import axios from "axios";

export default function EmailBill({show, translate, InvoiceID, query, onClose}) {
    const [emailFields, setEmailFields] = useState(() => getEmailFields());
    const thirdResource = useSelector(state => state.thirdResource);
    const documentName = 'invoice_' + InvoiceID + ".pdf";
    const dispatch = useDispatch();
    function getEmailFields() {
        return {
            EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full md:col-span-6'}),
            emails: new Field('emails', '', ['empty'], false, 'creatable', {
                addContainerClass: 'col-span-8',
                onEnterDown: true
            }, {
                isMulti: true,
                components: {DropdownIndicator: null},
                menuIsOpen: false,
                placeholder: 'Type email...'
            }),
            AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 -ml-px'}, {placeholder: 'Filter contacts...'}),
            subject: new Field('subject', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {addContainerClass: 'col-span-full'}),
        }
    }

    async function handleDownloadDocument() {
            try {
                await axios
                    .get(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                            InvoiceID: InvoiceID,
                            token: getJWT().access_token,
                            type: 3
                        })),
                        {responseType: 'blob'})
                    .then((response) => {
                        //Create a Blob from the PDF Streamwork
                        const file = new Blob([response.data], {type: 'application/pdf'})
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file)
                        //Open the URL on new Window
                        const pdfWindow = window.open(fileURL)
                        pdfWindow.location.href = fileURL
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                return {error}
            }

    }

    return (
        <EmailDocumentDialog
            show={show}
            title={translate('text.send_invoice')}
            widthClass={'max-w-5xl'}
            onClose={onClose}
            fields={emailFields}
            metadata={{
                AddContactID: {
                    api: 'api/' + Resources.ContactsQuick,
                    query: {offset: 0, sort: 'ASC', limit: 10},
                    searchMap: (item) => ({
                        value: item.Email,
                        label: item.FirstName + ' ' + item.LastName
                    })
                },
                EmailTemplateID: {
                    api: 'api/' + Resources.EmailTemplates,
                    query: {offset: 0, sort: 'ASC', limit: 10},
                    searchMap: (item) => ({
                        value: item.EmailTemplateID,
                        label: item.EmailTemplateName
                    })
                }
            }}

            onSubmit={(params) => {
                if (params) {
                    let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                        memo += it.value + ','
                        return memo
                    }, '')

                    dispatch(getResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {
                            status: 1,
                            InvoiceID: InvoiceID,
                            type: 2,
                            name: documentName,
                            InvoiceSendEmails: emails.substring(0, emails.length - 1),
                            subject: params.subject,
                            message: params.emailMessage
                        }, query),
                        resource: Resources.BillingDocuments,
                        piggyResource: Resources.WorkOrdersBilling,
                        errorMessage: true,
                        successMessage: 'Sent invoice to: ' + emails.substring(0, emails.length - 1)
                    }));

                    onClose();
                }
            }}
            onPrint={handleDownloadDocument}

            fileType={'pdf'}
            filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                InvoiceID: InvoiceID,
                token: getJWT().access_token,
                type: 1,
                name: documentName
            }))}

            thirdResource={thirdResource}
            translate={translate}
        />
    )
}