import React from "react";
import ShareIcon from "@heroicons/react/20/solid/ShareIcon";
import UserIcon from "@heroicons/react/20/solid/UserIcon";
import { classNames } from "../../../../../common/util/util-helpers";
import Tippy from "@tippyjs/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import {LoaderSmall} from "../../../../../common/components/loader";
import ImageAvatarLoader from "../../../../../common/components/image/image-avatar-loader";

export default function WoEventItem({event, eventTime, bgClass, isLastInSection, onShareEventClick, avatarRingClass, isLoading, translate}) {
    const {IsCustomerVisible} = event;

    return (
        <li key={event.WorkOrderEventID} className={classNames("px-2 pt-4 pm-4", bgClass)}>
            <div className={
                classNames(
                    "flex items-center pb-4 relative",
                    isLastInSection ? "border-b border-tm-gray-300" : undefined
                )
            }>
                <div className="">
                    {!isLastInSection && (
                        <span className="absolute top-5 left-5 -ml-px h-[calc(100%+1.5rem)] w-0.5 bg-tm-gray-200"
                              aria-hidden="true"/>
                    )}
                    <div className="relative flex items-start space-x-3">
                        <div
                            className={
                                classNames(
                                    "relative h-10 w-10 rounded-full bg-inverse flex items-center justify-center ring-8",
                                    avatarRingClass ?? "ring-tm-gray-50"
                                )
                            }
                        >
                            <ImageAvatarLoader
                                ContactID={event.ContactID}
                                size="sm"
                                hasImagePath={event.ImagePath}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="text-sm">
                                <div className="text-sm text-tm-gray-800 font-bold text-break">{translate(event.EventName)}</div>
                                <div className="text-sm text-tm-gray-800 text-break whitespace-pre-wrap">{event.Notes}</div>

                                <div className="pl-1 mt-2 space-y-2">
                                    <div
                                        className="text-xs text-tm-gray-600 flex">
                                        <UserIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                        {event.ContactID ? event.Contact : translate('text.system_event')}
                                    </div>

                                    <div
                                        className="text-xs text-tm-gray-500 flex items-center"
                                    >
                                        <ClockIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                        {eventTime}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {!!onShareEventClick && (
                    <div className="grow flex justify-end">
                        {!isLoading && (
                            <Tippy
                                delay={["500", 0]}
                                content={translate("text.toggle_share_with_customer")}
                            >
                                <button
                                    className={
                                        classNames(
                                            "mx-1 p-2 rounded-btn text-tm-gray-400 focus:outline-none focus-visible:ring-2 ring-primary-tint disabled:text-tm-gray-300 disabled:hover:bg-transparent disabled:hover:text-tm-gray-300",
                                            IsCustomerVisible ? "text-primary bg-sky-600/10 hover:bg-sky-600/200" : "hover:bg-tm-gray-200 hover:text-tm-gray-600"
                                        )}
                                    onClick={() => onShareEventClick(event)}
                                >

                                    <ShareIcon className="w-5 h-5 flex-shrink-0"/>
                                </button>
                            </Tippy>
                        )}

                        {isLoading && (
                            <span className="relative w-8 h-8 mr-2">
                            <LoaderSmall/>
                        </span>
                        )}
                    </div>
                )}
            </div>
        </li>
    )
}
