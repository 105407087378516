import React, {useEffect, useRef, useState} from 'react';
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import Tippy from "@tippyjs/react";
import { classNames } from "../../../../common/util/util-helpers";
import {RENTAL_STATUS} from "../../../../common/util/util-consts";

const ProgressArrowsWO = (props) => {
    const {WOStatusID, initialWOStatusID, locked, translate, forceResponsiveClasses, stepList, isLoading} = props;

    const btnRef = useRef(null);

    useEffect(() => {
        if (WOStatusID) {
            btnRef.current.focus();
        }
    }, [WOStatusID]);

    const getStatus = (stepID) => {
        switch (true) {
            case (WOStatusID === stepID):
                return "current";
            case (initialWOStatusID >= stepID):
                return "complete";
            case initialWOStatusID < stepID:
                return "upcoming"
        }
    }

    const steps = stepList ?? [
        {id: '01', name: 'Scheduled', stepID: 1, status: getStatus(1), description: "The appointment has been scheduled, but the customer has yet to arrive."},
        {id: '02', name: 'Confirmed', stepID: 2, status: getStatus(2), description: "The customer has confirmed their appointment."},
        {id: '03', name: 'Arrived', stepID: 3, status: getStatus(3), description: "The customer has arrived at the shop"},
        {id: '04', name: 'Check-In', stepID: 4, status: getStatus(4), description: " The vehicle has been checked in and is awaiting inspection."},
        {id: '05', name: 'Inspection in Progress', stepID: 5, status: getStatus(5), description: "The vehicle is being inspected to identify issues."},
        {id: '06', name: 'Estimate Prepared', stepID: 6, status: getStatus(6), description: "An estimate for the repairs has been prepared."},
        {id: '07', name: 'Estimate Approved', stepID: 7, status: getStatus(7), description: "The customer has approved the estimate."},
        {id: '08', name: 'Parts Ordered', stepID: 8, status: getStatus(8), description: "Necessary parts for the repair have been ordered."},
        {id: '09', name: 'Parts Received', stepID: 9, status: getStatus(9), description: "Ordered parts have been received and are ready for use."},
        {id: '10', name: 'Work in Progress', stepID: 10, status: getStatus(10), description: "Repairs are being carried out on the vehicle."},
        {id: '11', name: 'Quality Check', stepID: 11, status: getStatus(11), description: "The repair work is complete, and the vehicle is undergoing a final quality check."},
        {id: '12', name: 'Ready for Pickup', stepID: 12, status: getStatus(12), description: "The vehicle is ready for the customer to pick up."},
        {id: '13', name: 'Ready to invoice', stepID: 13, status: getStatus(13), description: "The invoice for the repair work is being prepared."},
        {id: '14', name: 'Completed', stepID: 14, status: getStatus(14), description: "The customer has picked up the vehicle, and the invoice has been settled."}
    ]

    return (
        <div className={
            classNames(
                props.className,
                "bg-inverse rounded-card overflow-y-auto lg:overflow-y-hidden max-w-5xl mx-auto max-h-96",
                !forceResponsiveClasses ? "lg:border lg:border-tm-gray-300" : undefined
            )}
        >
            <nav
                className="mx-auto"
                aria-label="Progress"
            >
                <ol
                    role="list"
                    className={
                        classNames(
                            "rounded-md overflow-hidden",
                            !forceResponsiveClasses ? "lg:grid lg:border-l lg:border-r lg:border-tm-gray-300 lg:rounded-none grid-flow-row grid-cols-7" : undefined
                        )
                    }
                >
                    {steps.map((step, stepIdx) => (
                        <li
                            key={step.id}
                            className={
                                classNames(
                                    "relative overflow-hidden",
                                    !forceResponsiveClasses ? "lg:flex-1 border-b-2 border-tm-gray-200" : undefined,
                                    step.status === 'inaccessible' ? "bg-stripes" : undefined,
                                )
                            }
                            title={step.description}
                        >
                            <div
                                className={classNames(
                                    stepIdx === 0
                                        ? "border-b-0 rounded-t-md"
                                        : "",
                                    stepIdx === steps.length - 1
                                        ? "rounded-b-md"
                                        : "border-b-0",
                                    "border border-tm-gray-300 overflow-hidden",
                                    !forceResponsiveClasses ? "lg:border-0" : undefined,
                                    !!locked ? "cursor-default" : ""
                                )}
                            >
                                {step.status === "complete" ? (
                                    <button
                                        className={classNames("w-full", !!locked ? "cursor-default" : "")}
                                        onClick={() => !locked ? props.onItemClick(step.stepID) : null}
                                    >
                                        <span
                                            className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-tm-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                !forceResponsiveClasses && stepIdx !== 0 ? "lg:border-0" : undefined,
                                                "px-6 py-2 items-center text-sm font-medium",
                                                !!locked ? "cursor-default" : ""
                                            )}
                                        >
                                     <span className="flex flex-shrink-0 justify-center">
                                        <span
                                            className="w-6 h-6 flex items-center justify-center bg-primary rounded-full ml-4">
                                            <CheckIcon
                                                className="w-3 h-3 text-white"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </span>
                                    <span className="mt-2 ml-4 min-w-0 flex flex-col">
                                        <span className="text-xs font-semibold tracking-wide">
                                            {step.name}
                                        </span>
                                    </span>
                                </span>
                                    </button>
                                ) : step.status === "current" ? (
                                    <button
                                        aria-current="step"
                                        className={classNames(!!locked ? "cursor-default" : "group w-full outline-0")}
                                        ref={btnRef}
                                    >
                                        <span
                                            className={
                                                classNames(
                                                    "absolute top-0 left-0 w-1 h-full bg-primary",
                                                    !forceResponsiveClasses ? "lg:w-full lg:h-1 lg:bottom-0 lg:top-auto" : undefined
                                                )
                                            }
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                !forceResponsiveClasses && stepIdx !== 0 ? "lg:pl-6" : undefined,
                                                "px-6 py-2 items-center text-sm font-medium",
                                                !!locked ? "cursor-default" : ""
                                            )}
                                        >

                                        {initialWOStatusID !== WOStatusID && (
                                            <span className="flex flex-shrink-0 justify-center">
                                                <span
                                                    className="w-6 h-6 flex items-center justify-center border-2 border-primary rounded-full ml-4">
                                                    <span className="text-primary">
                                                        {step.id}
                                                    </span>
                                                </span>
                                            </span>
                                        )}

                                            {initialWOStatusID === WOStatusID && (
                                                <span className="flex flex-shrink-0 justify-center">
                                                <span
                                                    className="w-6 h-6 flex items-center justify-center bg-primary rounded-full ml-4">
                                                    <CheckIcon
                                                        className="w-3 h-3 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </span>
                                            )}

                                            <span className="mt-2 ml-4 min-w-0 flex flex-col">
                                        <span
                                            className="text-xs font-semibold text-primary tracking-wide">
                                            {step.name}
                                        </span>
                                    </span>
                                </span>
                                    </button>
                                ) : (
                                    // <Tippy content={translate("text.dispatch_must_complete")}
                                    //        disabled={step.status !== 'inaccessible' || locked}>
                                        <button
                                            className={classNames("group", !!locked ? "cursor-default" : "group w-full")}
                                            onClick={() => !(locked || step.status === 'inaccessible') ? props.onItemClick(step.stepID) : null}
                                        >
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-tm-gray-200",
                                                        !forceResponsiveClasses ? "lg:w-full lg:h-1 lg:bottom-0 lg:top-auto" : undefined
                                                    )
                                                }
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    !forceResponsiveClasses && stepIdx !== 0 ? "lg:pl-6" : undefined,
                                                    "px-6 py-2 items-center text-sm font-medium",
                                                    !!locked ? "cursor-default" : ""
                                                )}
                                            >

                                                <span className="flex flex-shrink-0 justify-center">
                                                    <span
                                                        className={classNames("w-6 h-6 flex items-center justify-center border-2 rounded-full border-tm-gray-300 ml-4")}>
                                                        <span
                                                            className="text-tm-gray-500">
                                                            {step.id}
                                                        </span>
                                                    </span>
                                                </span>
                                                <span className="mt-2 ml-4 min-w-0 flex flex-col">
                                                    <span
                                                        className="text-xs font-semibold text-tm-gray-500 tracking-wide">
                                                        {step.name}
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                    // </Tippy>
                                )}

                                {stepIdx !== 0 ? (
                                    <>
                                        {/* Separator*/}
                                        <div
                                            className={
                                                classNames(
                                                    "hidden absolute top-0 left-0 w-3 inset-0",
                                                    !forceResponsiveClasses ? "lg:block" : undefined
                                                )
                                            }
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="h-full w-full text-tm-gray-300"
                                                viewBox="0 0 12 82"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path
                                                    d="M0.5 0V31L10.5 41L0.5 51V82"
                                                    stroke="currentcolor"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}

export default ProgressArrowsWO;
