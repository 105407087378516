import React, {useEffect, useState} from "react";
import Layout from "../../../../common/components/layout";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {getDefaultQueryFields, getUser} from "../../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import useQuery from "../../../../hooks/use-query";
import {checkPerm, getProp} from "../../../../common/util/util-helpers";
import LocalStorage from "../../../../util/localStorage";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../../common/components/layout/layout-components/page";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, DELETE_PERM, UPDATE_PERM} from "../../../../common/util/util-consts";
import {DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_SORT} from "../../../../util/util-constants";
import Buttons from "../../../../common/components/buttons";
import moment from "moment";
import {getUserTimeFormat} from "../../../../common/util/util-dates";

export default function WorkOrdersAppointmentsTab({ID, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());

    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {id: ID });


    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmDialogData, setConfirmDialogData] = useState({});

    /** UI events
     ================================================================= */

    useEffect(() => {
        fetchData(dispatch, query);
    }, [queryFields]);

    const handleEditResourceClick = (item) => {
        setIsResourceDialogOpen(true);
        setSelectedItem(item);
    }

    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setSelectedItem({});
    }

    const handleDeleteAppointmentClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteAppointmentClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete appointment <span className="font-bold text-red-600">{}</span>?
            </span>,
            title: translate("text.ConfirmDelete"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteAppointmentClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query,{WorkOrderScheduleID: item.WorkOrderScheduleID}),
            notificationMessage: translate(`text.appointment_deleted`),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate("message.appointment_delete"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <div className="flex flex-col gap-2 mt-2">
                <ResourceList
                    fields={getFields({})}
                    afterFiltersHtml={
                        <div className="ml-4 flex-shrink-0">
                            <Buttons
                                buttons={[
                                    {
                                        label: translate("btn.create"),
                                        type: "primary",
                                        onClick: handleCreateResourceClick
                                    }
                                ]}
                            />
                        </div>
                    }
                    resource={resource}
                    isLoading={isLoading}
                    queryFields={queryFields}
                    setQueryFields={setQueryFields}
                    listPath={getListPath()}
                    onRefreshTable={() => fetchData(dispatch, query)}
                    dispatch={dispatch}
                    query={query}

                    translate={translate}
                    selects={getSelects()}

                    onRowClick={handleEditResourceClick}
                    onEdit={handleEditResourceClick}
                    onDelete={handleDeleteAppointmentClick}

                    hasDeletePerm={checkPerm(getResourcePath(), DELETE_PERM)}
                    hasEditPerm={checkPerm(getResourcePath(), UPDATE_PERM)}

                />

                <ModalConfirm
                    show={confirmDialogData?.isOpen ?? false}
                    type={confirmDialogData?.type}
                    title={confirmDialogData?.title}
                    onConfirm={confirmDialogData?.onClick}
                    closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                    buttonLabel={confirmDialogData?.buttonLabel}
                    translate={translate}
                    onClose={closeConfirmDialog}
                >
                    {confirmDialogData?.content}
                </ModalConfirm>

                <ModalSaveResource
                    show={isResourceDialogOpen}
                    fields={getFields(selectedItem)}
                    //onInputChange={handleInputChange}
                    title={!!selectedItem?.BayNumber ? translate("modal_heading.edit_item") : translate("modal_heading.create_item")}
                    onClose={() => {
                        setIsResourceDialogOpen(false);
                        setSelectedItem({})
                    }}
                    onSubmit={(params) => {

                        if (!!params) {
                            params.id = ID;

                            if (!!selectedItem.WorkOrderScheduleID) {
                                params.WorkOrderScheduleID = selectedItem.WorkOrderScheduleID
                                dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: query,
                                    resource: getResourcePath(),
                                    piggyResource: getResourcePath(),
                                    successMessage: translate("message.appointment_updated"),
                                    errorMessage: true
                                }));
                            } else {
                                dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: query,
                                    resource: getResourcePath(),
                                    piggyResource: getResourcePath(),
                                    successMessage: translate("message.appointment_created"),
                                    errorMessage: true
                                }));
                            }

                            setSelectedItem(params); // in case of server error save form data
                            setIsResourceDialogOpen(false)

                        }
                    }}
                    addFieldContainerClass={"col-span-full"}
                    addBodyClass={"pb-48 text-tm-gray-900"}
                    isLoading={isLoading}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />
            </div>
        </React.Fragment>
)
}

/** Page methods
================================================================= */
const getPrimaryField = () => "";
const getResourcePath = () => Resources.WorkOrderSchedule;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query,

    }))

}

const getQueryFields = (translate) => {
    return getDefaultQueryFields(getPrimaryField(), translate);
}

export function getFields(item = {}) {
    const fieldTemplates = {
        ApptType: new Field('ApptType', '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
        StartDate: new Field('StartDate', '', ['empty'], false, 'datetime', {addContainerClass: "col-span-3"}),
        StartDateTime: new Field('StartDateTime', moment().format(getUserTimeFormat()), ['empty'], false, 'time-custom', {addContainerClass: "col-span-3", hideTable: true}),
        EndDate: new Field('EndDate', '', [], false, 'datetime', {addContainerClass: "col-span-3"}),
        EndDateTime: new Field('EndDateTime', moment().format(getUserTimeFormat()), ['empty'], false, 'time-custom', {addContainerClass: "col-span-3", hideTable: true}),
        ServiceBayID: new Field('ServiceBayID', '', [], false, 'select-search', {addContainerClass: "col-span-full"}),
        Notes: new Field('Notes', '', [''], false, "textarea", {addContainerClass: "col-span-full"}),
        CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone', {hideDialog: true}),
        UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search', {hideDialog: true}),


    }

    return fillFieldsFromData(fieldTemplates, item)
}


export function getSelects() {
    return {
        ServiceBayID: {
            api: 'api/' + Resources.ServiceBaysQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ServiceBayID,
                label: item.BayNumber
            })
        }
    }
}
