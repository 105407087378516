import {useDispatch, useSelector} from "react-redux";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import {Field, FieldsManager} from "../../../data/services/fields";
import {useEffect, useState} from "react";
import {getResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import useQuery from "../../../hooks/use-query";
import {getDefaultTableOptions} from "../../../common/util/util-helpers";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import {checkPerm} from "../../../util/util";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, READ_PERM} from "../../../common/util/util-consts";
import moment from "moment/moment";
import {formatMoney} from "../../../common/util/util-formaters";
import ModalDefault from "../../../common/components/modal/modal-default";
import InvoiceExpenseDetailsDialog from "../../../common/components/modal/invoice-expense-details-dialog";
import {getThirdResource} from "../../../data/actions/thirdResource";

export default function PartsReportTab({translate}) {
    const pagePath = "parts_report_tab";
    const tablePageDefaults = {
        behaviour: {
            canAdjustWidth: true
        },
        columns: {}
    }
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const thirdResource = useSelector(state => state.thirdResource);
    const dialogResource = useSelector(state => state.dialogResource);
    const isLoading = resource.isLoading;
    const data = resource?.data?.list ?? [];
    const count = resource?.data?.count ?? 0;


    /** State
     ================================================================= */
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate));
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedInvoiceExpenseItem, setSelectedInvoiceExpenseItem] = useState({});
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const [isInvoiceExpenseDialogOpen, setIsInvoiceExpenseDialogOpen] = useState(false);
    const [queryFields, setQueryFields] = useQuery(getQueryFields(), pagePath)
    const query = FieldsManager.getFieldKeyValues(queryFields);
    query.group = 1;

    /** Data events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: getResourceName()
        }))
    }

    /** UI events
     ================================================================= */
    function handleViewReportClick(selectedItem) {
        setSelectedItem(selectedItem);
        setIsReportDialogOpen(true);
    }

    function handleInvoiceExpenseClick(item = null) {
        setSelectedInvoiceExpenseItem(item);
        setIsInvoiceExpenseDialogOpen(true);
    }

    function handleQueryChange(name, value, resetOffset = false) {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        if (resetOffset) {
            queryFieldsUpdate.offset.value = 0;
        }

        setQueryFields(queryFieldsUpdate)
    }

    /** Helpers
     ================================================================= */
    function getFields() {
        return {
            ProductService: new Field('ProductService', '', [''], false, 'text', {
                omitSort: true
            }),
            SKU: new Field('SKU', '', [''], false, 'text', {
                omitSort: true
            }),
            OEMNumber: new Field('OEMNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], false, 'text', {
                label: "Location",
                omitSort: true
            }),
            ReorderPoint: new Field('ReorderPoint', '', [''], false, 'text', {
                label: "minimum_recommended_stock",
                omitSort: true
            }),
            MaxRecommendedStock: new Field('MaxRecommendedStock', '', [''], false, 'text', {
                label: "maximum_recommended_stock",
                omitSort: true
            }),
            Reserved: new Field('Reserved', '', [''], false, 'text', {
                omitSort: true
            }),
            Qty: new Field('Qty', '', [''], false, 'text', {
                label: "current_stock",
                omitSort: true
            }),
            TotalTotalAmount: new Field('TotalTotalAmount', '', [''], false, 'money', {
                omitSort: true
            }),
        }
    }


    function getQueryFields() {
        return {
            // group: new Field('group', '1', [''], false, 'text', {}, {}),
            query: new Field('query', '', [''], false, 'search', {}, {}),
            sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'ProductService', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            limit: new Field('limit', '10', [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    function getResourceName() {
        return Resources.WorkOrderProductServicesReport
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    /** Component Body
     ================================================================= */
    return (
        <>
            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFields}
                    handleInputChange={handleQueryChange}
                    translate={translate}
                    exclu
                    largeGrid
                    forceDialog
                    isLoading={isLoading}
                    onRefreshTable={fetchData}
                />

                <ResourceTable
                    maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                    data={data}
                    fields={getFields()}
                    queryFields={queryFields}

                    onView={(item) => {
                        handleViewReportClick(item)
                    }}
                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}
                />

                <NoRecords
                    show={(data.length === 0) && !isLoading}
                    title={translate('text.no_records')}
                    addClass={'pt-16 pb-10'}
                />


                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={handleQueryChange}
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableCardFooter>
            </TableCard>

            <ResourceTableDialog
                widthClass={'max-w-6xl'}
                show={isReportDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.ProductServiceReport}
                title={'Inventory report'}
                dispatch={dispatch}
                isFooterVisible={true}
                onView={(item) => {
                    handleInvoiceExpenseClick(item)
                }}
                hasViewPerm={checkPerm(Resources.ExpenseExpense, READ_PERM) && checkPerm(Resources.InvoicesInvoice, READ_PERM)}
                defaultAction={(item) => {
                    if (item.Type === 1 && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
                        return false;
                    }

                    if (item.Type !== 1 && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
                        return false;
                    }

                    handleInvoiceExpenseClick(item)
                }}
                limit={50}
                fields={{
                    Date: new Field('Date', '', [], false, 'date', {
                        omitSort: true
                    }),
                    Description: new Field('Description', '', [''], false, 'textarea', {omitSort: true}),
                    Type: new Field('Type', '', [''], false, 'custom', {
                        render: (it) => {
                            return it.Type === 1 ? 'Bought' : 'Sold'
                        },
                        omitSort: true
                    }),
                    FromTo: new Field('FromTo', '', [''], false, 'custom', {
                        render: (it) => it.FromTo
                    }),
                    Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true}),
                    Qty: new Field('Qty', '', [''], false, 'text', {omitSort: true}),
                    TaxID: new Field('TaxID', '', [''], false, 'select-search', {omitSort: true}),
                    TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
                }}

                fieldsFilter={{
                    StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                    EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                    OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4'}, {
                        isClearable: true
                    }),
                }}

                htmlBefore={(
                    <div className={"p-2 text-lg"}>
                        Current profit for the filtered
                        records: {formatMoney(dialogResource?.data?.TotalTotalAmount ?? 0)}
                    </div>
                )}

                tableKey="Name"
                defaultQuery={{id: selectedItem?.ProductServiceID ?? null}}
                onClose={() => setIsReportDialogOpen(false)}
                translate={translate}
                selects={{
                    OrganizationID: {
                        api: 'api/' + Resources.OrganizationsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            value: item.OrganizationID,
                            label: item.LegalName,
                            Organization: item
                        })
                    }
                }}
                htmlAfter={() => {
                    return <ModalDefault
                        show={isInvoiceExpenseDialogOpen}
                        widthClass={'max-w-5xl w-screen'}
                        title={"Details"}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={() => setIsInvoiceExpenseDialogOpen(false)}
                    >
                        <InvoiceExpenseDetailsDialog
                            resource={thirdResource}
                            isLoading={thirdResource?.isLoading}
                            translate={translate}
                            dispatch={dispatch}
                            showLoadReferenceLinks={true}

                            disableComments={true}
                            canDeletePayment={false}
                            disableAddReference={true}
                            disableTransactionImport={true}

                            // Data events
                            onFetchData={() => {
                                if (selectedInvoiceExpenseItem?.ID) {
                                    dispatch(getThirdResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            [selectedInvoiceExpenseItem.Type === 1 ? "ExpenseID" : "InvoiceID"]: selectedInvoiceExpenseItem.ID
                                        },
                                        resource: selectedInvoiceExpenseItem.Type === 1 ? Resources.ExpenseExpense : Resources.InvoicesInvoice
                                    }))
                                }
                            }}
                        />
                    </ModalDefault>
                }}
            />
        </>
    )
}