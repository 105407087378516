import React, {Component} from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import FileList from "../../dropzone/file-list";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../util/util-fields";
import FieldText from "../../fields/field-text";
import FieldContainer from "../../fields/field-container";
import FieldDropdownSelect from "../../fields/field-dropdown-select";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Env from "../../../../util/env";
import FieldTextarea from "../../fields/field-textarea";
import {
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_INCOME,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from "../../../util/util-consts";
import Resources from "../../../../data/services/resources";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import NavResponsive from "../../nav-responsive";
import ModalDefault from "../modal-default";
import {classNames, focusOnElement, getProp} from "../../../util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import {createResource, updateResource} from "../../../../data/actions/resource";
import {download} from "../../../../data/actions/download";
import {getJWT} from "../../../util/util-auth";
import {processResponse} from "../../../../data/services/api-util";
import FieldsForm from "../../fields/fields-form";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";
import {XMarkIcon} from "@heroicons/react/20/solid";
import InfoBar from "../../info-paragraph/info-bar";

export default class PartsInfoDialog extends Component {
    constructor(props) {
        super(props);
        const tabs = [
            {
                name: 'Service',
                resource: 'Service',
                current: false,
                visible: false
            },
            {
                name: 'Inventory',
                resource: 'Inventory',
                current: true,
                visible: true
            },
            {
                name: 'NonInventory',
                resource: 'NonInventory',
                current: false,
                visible: false
            },
            /*
            {
                name: 'Asset',
                resource: 'Asset',
                current: this.props.editedItem.ProductServiceTypeID === 4,
                visible: (this.props.buyAsset || this.props.sellAsset)
            },*/
        ]

        this.state = {
            fields: this.getFields(),
            assetFields: this.getAssetFields(),
            HideModalAnimation: false,
            files: [],
            documentPath: this.props?.editedItem?.ImagePath,
            tabs: tabs
        };

        this.INVENTORY = 1;

    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.editedItem?.ImagePath !== this.props?.editedItem?.ImagePath && (prevProps?.editedItem?.ImagePath !== undefined && this.props?.editedItem?.ImagePath !== undefined)) {
            this.setState({
                documentPath: this.props?.editedItem?.ImagePath
            })
        }

        if (prevProps.thirdResource.isLoading && !this.props.thirdResource.isLoading && this.props.thirdResource.data) {
            const resource = getProp(this.props.thirdResource, "data", null);
            this.setState({
                fields: this.getFields(resource, this.props.editedItem)
            }, () => focusOnElement());
        }
    }

    getFields = (resource, item) => {
        const isEditMode = this.props.editedItem && this.props.editedItem.ProductServiceID;
        resource = isEditMode ? item : resource;

        const fieldTemplates = {
            ProductService: new Field('ProductService', this.props.name || "", ['empty'], false, 'text', {label: "name"}),
            SKU: new Field('SKU', '', [''], false, 'text', {label: 'sku'}),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search'),
            OEMNumber: new Field('OEMNumber', '', [''], false, 'text'),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], false, 'text'),
            ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], false, 'select-search'),
            IncomePrice: new Field('IncomePrice', '', [''], false, 'money'),
            ExpensePrice: new Field('ExpensePrice', '', [''], false, 'money'),
            IncomeDescription: new Field('IncomeDescription', '', [''], false, 'textarea'),
            ExpenseDescription: new Field('ExpenseDescription', '', [''], false, 'textarea'),
            IncomeAccountID: new Field('IncomeAccountID', '', [''], false, 'select-search'),
            ExpenseAccountID: new Field('ExpenseAccountID', '', [''], false, 'select-search'),
            VendorID: new Field('VendorID', '', [''], false, 'select-search', {label: 'recommendedVendor', addContainerClass: 'col-span-full'}),
            ProductServiceTypeID: new Field('ProductServiceTypeID', 1, ['']),
            InventoryAccountID: new Field('InventoryAccountID', '', [''], false, 'select-search', {addContainerClass: "col-span-4"}),
            ReorderPoint: new Field('ReorderPoint', '', [''], false, 'integer', {
                addContainerClass: "col-span-4",
                placeholder: "0"
            }),
            InventoryCount: new Field('InventoryCount', '', [], isEditMode, 'integer', {
                addContainerClass: "col-span-4",
                placeholder: "0"
            }),
            InherentProductServiceID: new Field('InherentProductServiceID', '', [], false, 'select-search', {addContainerClass: "col-span-6"}),
            DirtyProductServiceID: new Field('DirtyProductServiceID', '', [], false, 'select-search', {addContainerClass: "col-span-6"}),
        }

        return fillFieldsFromData(fieldTemplates, resource);
    }

    getAssetFields = () => {
        return {
            FixedAsset: new Field('FixedAsset', this.props.location?.query?.input ? this.props.location.query.input : '', ['empty']),
            Description: new Field('Description', '', []),
            PONumber: new Field('PONumber', "", []),
            SerialNumber: new Field('SerialNumber', "", []),
            Amount: new Field('Amount', "", ['float_up_to_12_not_require']),
            SalvageAmount: new Field('SalvageAmount', "", ['float_up_to_12_not_require']),
            StartDate: new Field('StartDate', "", []),
            EndDate: new Field('EndDate', "", []),
            WarrantyExpirationDate: new Field('WarrantyExpirationDate', "", []),
            AccountID: new Field('AccountID', "", ['empty'], false, 'select-search'),
            AccumulatedAccountID: new Field('AccumulatedAccountID', "", [], false, 'select-search'),
            DepExpenseAccountID: new Field('DepExpenseAccountID', "", [], false, 'select-search'),
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get("user"),
            resource: Resources.AccountingSettings,
        }));
    };

    handleInputChange = (name, value) => {
        let fields = this.state.fields;
        if (name === 'ProductServiceTypeID') {
            fields = FieldsManager.resetFieldsErrors(fields)

            fields.InventoryAccountID.validate = value === this.INVENTORY ? ['empty'] : [''];
        }

        this.setState({
            fields: FieldsManager.updateField(fields, name, value)
        });
    };

    handleDownloadDocumentsClick = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderProdServicesImage,
            query: Object.assign({}, {
                id: this.props.editedItem?.ProductServiceID,
                name: this.props.editedItem?.ImagePath
            }),
            notificationMessage: 'Successfully downloaded document!'
        }))
    }

    submit = () => {
        const list = this.state.fields.ProductServiceTypeID.value === this.INVENTORY ? ["ProductService", "InventoryCount", "InventoryAccountID"] : ["ProductService"];

        this.setState({
            fields: FieldsManager.validateFields(this.state.fields, list),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields, list)) {
                if (this.props.editedItem?.ProductServiceID) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            ExpenseAccountID: this.state.fields.ExpenseAccountID.value.value,
                            IncomeAccountID: this.state.fields.IncomeAccountID.value.value,
                            ProductServiceCategoryID: this.state.fields.ProductServiceCategoryID.value?.value,
                            ProductServiceID: this.props.editedItem.ProductServiceID,
                            id: this.props.editedItem.ProductServiceID,
                            ReorderPoint: this.state.fields.ReorderPoint.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.ReorderPoint.value : 0,
                            InventoryCount: this.state.fields.InventoryCount.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.InventoryCount.value : 0,
                            imagePath: this.state.documentPath ? undefined : -1
                        }),
                        piggyResource: Resources.WorkOrderProductsServices,
                        query: this.props.query,
                        resource: Resources.WorkOrderProductsServices,
                        file: this.state.files,
                        fileResource: Resources.WorkOrderProdServicesImage,
                        errorMessage: true,
                        successMessage: this.props.translate("text.product_service_information_saved"),
                    }));
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get("user"),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            ExpenseAccountID: this.state.fields.ExpenseAccountID.value?.value,
                            IncomeAccountID: this.state.fields.IncomeAccountID.value?.value,
                            ProductServiceCategoryID: this.state.fields.ProductServiceCategoryID.value?.value,
                            ReorderPoint: this.state.fields.ReorderPoint.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.ReorderPoint.value : 0,
                            InventoryCount: this.state.fields.InventoryCount.value && this.state.fields.ProductServiceTypeID.value === 1 ? this.state.fields.InventoryCount.value : 0,
                        }),
                        resource: Resources.WorkOrderProductsServices,
                        query: this.props.query,
                        piggyResource: Resources.WorkOrderProductsServices,
                        file: this.state.files,
                        fileResource: Resources.WorkOrderProdServicesImage,
                        errorMessage: true,
                        successMessage: this.props.translate("text.product_service_information_saved"),
                    }));

                    if (this.props.setCreatedItemName) {
                        this.props.setCreatedItemName(this.state.fields.ProductService.value)
                    }
                }

                this.close();
            }
        });
    }

    /** UI Events
     ================================================================= */
    handleAssetInputChange = (name, value) => {
        let fields = this.state.assetFields;
        if (name === 'ProductServiceTypeID') {
            fields = FieldsManager.resetFieldsErrors(fields)
        }

        this.setState({
            assetFields: FieldsManager.updateField(fields, name, value)
        });
    };

    close = () => {
        this.props.onClose();
    }
    handleTabChange = (resource) => {
        if (!this.props.editedItem.ProductServiceID) {
            if (resource === this.state.selectedTab) {
                return false
            }

            this.setState({
                tabs: this.state.tabs.map((it) => {
                    it.current = it.resource === resource
                    return it
                }),
                selectedTab: resource
            })

            if (resource === 'Service') {
                this.handleInputChange("ProductServiceTypeID", 3)
            }

            if (resource === 'Inventory') {
                this.handleInputChange("ProductServiceTypeID", 1)
            }

            if (resource === 'NonInventory') {
                this.handleInputChange("ProductServiceTypeID", 2)
            }
            if (resource === 'Asset') {
                this.handleInputChange("ProductServiceTypeID", 4)
            }
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <ModalDefault
                show={true}
                title={translate("modal_heading.product_service_information")}
                widthClass={"max-w-5xl"}
                buttonLabel={translate("btn.save")}
                onButtonClick={this.submit}
                onClose={this.close}
                closeButtonLabel={translate("btn.cancel")}
                translate={translate}
            >
                {/*<ModalDefault*/}
                {/*    isLoading={this.props.thirdResource.isLoading}*/}
                {/*    large*/}
                {/*    title={translate("modal_heading.product_service_information")}*/}
                {/*    close={this.close}*/}
                {/*    closeButtonLabel={translate('btn.cancel')}*/}
                {/*    onClose={this.close}*/}
                {/*    primaryButtonLabel={translate('Save')}*/}
                {/*    onPrimaryButtonClick={this.submit}*/}
                {/*    {...this.props}*/}
                {/*>*/}
                <div className="mb-5 hidden sm:block">
                    <NavResponsive
                        tabs={this.state.tabs}
                        onTabChange={this.handleTabChange}
                        translate={translate}
                    />
                </div>

                <div className="p-5 block sm:hidden">
                    <MobileTabs
                        tabs={this.state.tabs}
                        onTabChange={this.handleTabChange}
                        translate={translate}
                    />
                </div>

                {(this.state.fields.ProductServiceTypeID.value !== 4) && (
                    <div className="p-5">
                        <div className="grid grid-cols-12 gap-4">
                            <div className="md:col-span-7 col-span-full grid grid-cols-12 gap-4">
                                <div className="md:col-span-8 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ProductService}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ProductService}
                                            placeholder={""}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-4 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.SKU}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SKU}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ProductServiceCategoryID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ProductServiceCategoryID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.WorkOrderProductsServicesCategory, {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    return {
                                                                        label: it.ProductServiceCategory,
                                                                        value: it.ProductServiceCategoryID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-4 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.OEMNumber}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.OEMNumber}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="md:col-span-4 col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.CrossRefNumber}
                                        translate={translate}
                                    >
                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.CrossRefNumber}
                                        />
                                    </FieldContainer>
                                </div>

                                <div className="col-span-full">
                                    <FieldContainer
                                        item={this.state.fields.ProductServicePartLocationID}
                                        translate={translate}
                                    >
                                        <FieldDropdownSelect
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.ProductServicePartLocationID}
                                            addClass="form-control"
                                            defaultOptions={true}
                                            loadOptions={
                                                (inputValue, callback) => {
                                                    axios.get(
                                                        Env.getApiUrl("api/" + Resources.WorkOrderPartLocationsQuick, {query: inputValue}),
                                                        {
                                                            headers: {
                                                                'Authorization': 'Bearer ' + getJWT().access_token
                                                            }
                                                        }
                                                    )
                                                        .then((response) => {
                                                            const result = processResponse(response);
                                                            if (result && result.status === 0) {
                                                                const list = result.data.list.map((it) => {
                                                                    return {
                                                                        label: it.ProductServiceLocation,
                                                                        value: it.ProductServiceLocationID
                                                                    };
                                                                });
                                                                callback(list);
                                                            }
                                                        })
                                                }
                                            }
                                        />
                                    </FieldContainer>
                                </div>
                            </div>

                            <div className="md:col-span-5 col-span-full">
                                <div className="relative">
                                    {!this.props.editedItem.ImagePath && (
                                        <Dropzone
                                            onDrop={(acceptedFiles) => {
                                                this.setState({
                                                    files: acceptedFiles.map(file => Object.assign(file, {
                                                        preview: URL.createObjectURL(file)
                                                    })),
                                                    canSubmit: true
                                                });
                                            }}
                                            //     onDragEnter={() => null}
                                            // onDragLeave={() => {
                                            //     this.setState({dropzoneActive: false, dropzoneRejected: false})
                                            // }}
                                            // onDropAccepted={() => {
                                            //     this.setState({dropzoneActive: true})
                                            // }}
                                            // onDropRejected={() => {
                                            //     this.setState({dropzoneRejected: true})
                                            // }}
                                            multiple={false}
                                            maxFiles={1}
                                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                            accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                        >
                                            {({
                                                  getRootProps,
                                                  getInputProps,
                                                  isDragReject,
                                                  isDragAccept,
                                                  isDragActive
                                              }) => (
                                                <section>
                                                    <div {...getRootProps()}
                                                         className={
                                                             classNames(
                                                                 "rounded-card flex justify-center items-center border-2 border-dashed h-32",
                                                                 isDragAccept ? "border-green-600" : undefined,
                                                                 isDragReject ? "border-red-600" : undefined,
                                                                 isDragActive ? "border-primary" : undefined,
                                                                 !isDragAccept && !isDragReject && !isDragActive ? "border-tm-gray-300" : undefined,
                                                             )
                                                         }>
                                                        <div>
                                                            <input {...getInputProps()} />
                                                            <div className="p-4 text-center text-break">
                                                                <em>{translate("field.image_types")}</em>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    )}

                                    {/*ImagePath*/}
                                </div>
                            </div>
                        </div>

                        {/*Files start*/}
                        {!!this.state.files.length > 0 && (
                            <div className="relative inline-block mt-10">

                                <button type="button"
                                        className="absolute top-0 left-full"
                                        aria-label="Close"
                                        onClick={() => {
                                            this.setState({files: []})
                                        }}>
                                    <XMarkIcon className="w-5 h-5 text-tm-gray-600"/>
                                </button>


                                <img
                                    className={"bg-inverse img-thumbnail border-0 mb-4 list-thumbnail"}
                                    src={
                                        (this.state.files.length > 0) ? this.state.files[0].preview : this.props.editedItem?.ProductServiceID > -1 ? Env.getApiUrl("api/" + Resources.WorkOrderProdServicesImage, {
                                            token: getJWT().access_token,
                                            id: this.props.editedItem.ProductServiceID + "&v=" + (this.props.editedItem.CreateUpdateDate ? this.props.editedItem.CreateUpdateDate.replace(/[^0-9]/g, '') : null) // version is to prevent browser caching from displaying original image after update
                                        }) : ""
                                    }
                                    alt={""}
                                />
                            </div>
                        )}

                        {this.state.documentPath && (
                            <>
                                <div className='mt-8 mb-1 text-sm font-semibold'>Attached image:</div>
                                <FileList
                                    addClass=""
                                    filePath={this.props.editedItem.ImagePath}
                                    onFileDelete={() => {
                                        this.setState({
                                            documentPath: undefined
                                        })
                                    }}
                                    onFileDownload={this.handleDownloadDocumentsClick}
                                />
                            </>
                        )}

                        {!this.state.documentPath && !!this.props.editedItem.ImagePath && (
                            <div className="mt-8 mb-1">
                                <InfoBar type="warning">
                                    Image {this.props.editedItem.ImagePath} marked for
                                    deletion on save action.
                                    <button
                                        onClick={() => {
                                            this.setState({documentPath: this.props.editedItem.ImagePath})
                                        }}
                                        className="ml-auto mr-4 font-bold text-primary"
                                    >
                                        Revert
                                    </button>
                                </InfoBar>
                            </div>
                        )}
                    </div>
                )}

                <div className="grid grid-cols-12 gap-4 p-6">
                    <FieldsForm
                        includeFields={['VendorID', 'InherentProductServiceID', 'DirtyProductServiceID']}
                        fieldsState={this.state.fields}
                        onChange={this.handleInputChange}
                        translate={translate}
                        isLoading={this.props.thirdResource?.isLoading}
                        selects={{
                            VendorID: {
                                api: 'api/' + Resources.Vendors,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.LegalName + ", " + item.AddressName,
                                    value: item.VendorID
                                })
                            },
                            InventoryAccountID: {
                                api: 'api/' + Resources.AccountsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.AccountName,
                                    value: item.AccountID
                                })
                            },
                            InherentProductServiceID: {
                                api: 'api/' + Resources.WorkOrderProductsServices,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => {
                                    return ({
                                        label: item.ProductService,
                                        value: item.ProductServiceID
                                    })
                                },
                            },
                            DirtyProductServiceID: {
                                api: 'api/' + Resources.WorkOrderProductsServices,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.ProductService,
                                    value: item.ProductServiceID
                                })
                            }
                        }}
                    />
                </div>
            </ModalDefault>
        )
    }
}
